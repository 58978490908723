import { useEffect, useState, useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
  Button,
  Backdrop
} from "@mui/material";
import { tokens } from "../../theme";
import { subSeconds, subDays } from 'date-fns'
import format from 'date-fns/format'

import { useStateContext } from "../../contexts/ContextProvider";
import { workDuration, getLeavePlannerData, editLeave} from "../../services/leaveService";
import { getEmployees } from "../../services/userService";

import Popup from "../../components/Popup";
import Header from "../../components/Header";
import LeaveApplicationForm from "../leave_application_form/leave_application_form";
import LoadingOverlay from "../global/LoadingOverlay";
import CustomSnackBar from "../../components/CustomSnackBar";


const Calendar = () => {
  const calendarRef = useRef(null); // Create a ref to access the FullCalendar instance
  const [selectedEditLeavePreviousState, setSelectedEditLeavePreviousState] = useState(null);
  // let initialEvents = [];
  
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  
  const { currentEvents, setCurrentEvents, user, leaveTaken, setLeaveTaken, setLeaveGroups,
          leaveTypes, setLeaveTypes, setResultMessage, setEmployees, setSelectedLeaveType, publicHolidays, setPublicHolidays,
          selectedLeave, setSelectedLeave, selectedEditLeave, setSelectedEditLeave, setLeaveBalances, openLeaveApplicationPopup, 
          setOpenLeaveApplicationPopup, openFailedPopup, setOpenFailedPopup, range, setRange, setDuration, 
          resultMessage, isLoading, setIsLoading, snackbar, setSnackbar, setBackdropOpen } = useStateContext(); 

  const [isInitialLoading, setIsInitialLoading] = useState(true);

  const refLeaveApplicationPopup = useRef(null);
  const refFailedPopup = useRef(null);

  useEffect(() => {
    const fetchLeaveValues = async () => {
      setIsLoading(true);
      //set this from all leave taken if admin?
      const leave_planner_data = await getLeavePlannerData(user, leaveTaken);
      // const employees = await getEmployees();

      let leave_taken = leave_planner_data.leave_taken;

      const public_holidays = leave_planner_data.public_holidays;
      // const public_holiday_names = public_holidays.map(holiday => holiday.name);

      // leave_types.forEach(leave_type => {
      //   public_holiday_names.forEach(name => {
      //     leave_type[name] = 1;
      //   });
      // });
      
      setEmployees(leave_planner_data.employees);
      setLeaveTaken(leave_taken);
      setLeaveTypes(leave_planner_data.leave_types);
      setLeaveGroups(leave_planner_data.leave_groups);
      setLeaveBalances(leave_planner_data.leave_balances);
      setPublicHolidays(public_holidays);
      setIsInitialLoading(false);
      setIsLoading(false);
    };
    
    // initialEvents = fetchLeaveValues();
    if (leaveTaken.length === 0){
      fetchLeaveValues();
    } else {
      setIsLoading(false);
      setIsInitialLoading(false);
    }
  }, []);

  useEffect(() => {
    
    if (leaveTypes){      
      if (selectedEditLeave){
        setIsLoading(true);
        let startStr = format(range[0].startDate, "yyyy-MM-dd'T'HH:mm:ss");
        let endStr = format(range[0].endDate, "yyyy-MM-dd'T'HH:mm:ss");
        const leave_type_id = selectedEditLeave.extendedProps.leave_type_id;

        // let newManualDuration = { enabled: false };
        let newRange = { ...range, manual_duration_enabled: false };
        
        if(startStr.split("T")[1] === "00:00:00" && endStr.split("T")[1] === "23:59:59"){ //!selectedEditLeave.allDay
          startStr = startStr.split("T")[0];
          endStr = endStr.split("T")[0];
        } else {
          const leave_type_start_time = leaveTypes.find((row) => row.id === leave_type_id)?.start_time;
          const leave_type_end_time = leaveTypes.find((row) => row.id === leave_type_id)?.end_time;

          newRange = {
            ...newRange,
            enablmanual_duration_enableded: true, 
            leave_type_times: { start_time: leave_type_start_time, end_time: leave_type_end_time }, 
            selected_times: { start_time: startStr.split("T")[1], end_time: endStr.split("T")[1] } 
          }
          
          // setManualDuration(newManualDuration);
        }

        let worked_duration_results = workDuration(newRange, publicHolidays, leave_type_id, leaveTypes);
        const worked_duration = worked_duration_results["duration"];
        setDuration(worked_duration);

        if (worked_duration_results["new_end_date"]){
          setRange([{
            ...range[0],
            endDate: new Date(worked_duration_results["new_end_date"])
          }])
        }

        const data = {"employee_number": user.employee_number,
                    "edited_leave_id": selectedEditLeave.id,
                    "edited_leave_startStr": startStr,
                    "edited_leave_endStr": endStr,
                    "duration": worked_duration,
                    "selected_leave_type":leave_type_id
        }
                    
        const _editLeave = async (data) => {
          const edit_leave_response = await editLeave(data);

          // setIsLoading(false);

          if (edit_leave_response["status"] !== 200){
            setResultMessage(edit_leave_response["data"]["message"]);
            setOpenFailedPopup(true);

          }else{
            setLeaveBalances(edit_leave_response["data"]["new_leave_balances"]);
            setLeaveTaken(edit_leave_response["data"]["employee_leave_taken"]);
            setSnackbar({ children: 'Leave successfully edited', severity: 'success' });
            setBackdropOpen(true);
          }

          setSelectedEditLeave(null);
          setIsLoading(false);
        }

        _editLeave(data);
      }
    }


  }, [range, selectedEditLeave, selectedLeave, snackbar]);  

  useEffect(() => {}, [isLoading]);

  const handleDateClick = (selected) => {  
    // console.log("DC", selected);
    // const calendarApi = selected.view.calendar;
    // calendarApi.unselect();
    
    setRange([{ 
      startDate: selected.start,
      endDate: subSeconds(selected.end, 1),
      key: 'selection',
      manual_duration_enabled:false, 
      leave_type_times: { start_time: "", end_time: "" }, 
      selected_times: { start_time: "", end_time: "" } 
    }]);

    setSelectedLeave(selected);
    // console.log(selected);
    // setManualDuration({
    //   enabled:false, 
    //   leave_type_times: { start_time: "", end_time: "" }, 
    //   selected_times: { start_time: "", end_time: "" } 
    // })
    setOpenLeaveApplicationPopup(true);
  };

  const handleEventClick = (selected) => {
    const event = selected.event;

    // search for leavetaken
    // const selected_leave = leaveTaken.find(item => item.id === selected.event.id);
    setRange([{ 
      startDate: event.start,
      endDate: event.allDay ? subSeconds(event.end, 1) : event.end,
      key: 'selection',
      manual_duration_enabled:!event.allDay, 
      leave_type_times: { 
        start_time: leaveTypes.find((row) => row.id === event.extendedProps.leave_type_id)?.start_time, 
        end_time: leaveTypes.find((row) => row.id === event.extendedProps.leave_type_id)?.end_time 
      }, 
      selected_times: { 
        start_time: event.startStr.split("T").length > 1 ? (event.startStr.split("T")[1].split(":")[0] + ":" + event.startStr.split("T")[1].split(":")[1]) : "", 
        end_time: event.endStr.split("T").length > 1 ? (event.endStr.split("T")[1].split(":")[0] + ":" + event.endStr.split("T")[1].split(":")[1]) : "",
      } 
    }]);

    const newSelectedLeave = { ...event };
    newSelectedLeave.startStr = event.startStr;
    newSelectedLeave.endStr = event.endStr;
    newSelectedLeave.extendedProps = event.extendedProps;
    newSelectedLeave.id = event.id;
    newSelectedLeave.title = event.title;
    newSelectedLeave.calendaApiEventToUpdate = calendarRef.current.getApi().getEventById(event.id);

    // eventToUpdate.setStart('2024-04-18'+'T09:00:00');
    // eventToUpdate.setEnd('2024-04-18'+'T17:30:00');

    setSelectedLeave(newSelectedLeave);
    setSelectedLeaveType(event.extendedProps.leave_type_id);
    // setSelectedEditLeave(event);
    // setSelectedLeaveType(leaveTypes.find(LTY_item => LTY_item.name === event.title)?.id);

    setOpenLeaveApplicationPopup(true);

  };


  const handleRetry = () => {
    setOpenFailedPopup(false);
    setOpenLeaveApplicationPopup(true);
    selectedEditLeavePreviousState.revert();
  };

  const handleEventResize = async (info) => {
    const event = info.event;

    //to verify balance before sending to backend
    // const unedited_leave_type = leaveTypes.find(LTY_item => LTY_item.name === event.title)?.id;
    // const unedited_range = [{
    //   startDate: event.start,
    // const unedited_duration = workDuration(range, unedited_leave_type, leaveTypes);

    setRange([{ 
      startDate: event.start,
      endDate: event.allDay ? subSeconds(event.end, 1) : event.end,
      key: 'selection',
      manual_duration_enabled:!event.allDay, 
      leave_type_times: { 
        start_time: leaveTypes.find((row) => row.id === event.extendedProps.leave_type_id)?.start_time, 
        end_time: leaveTypes.find((row) => row.id === event.extendedProps.leave_type_id)?.end_time 
      }, 
      selected_times: { 
        start_time: event.startStr.split("T").length > 1 ? (event.startStr.split("T")[1].split(":")[0] + ":" + event.startStr.split("T")[1].split(":")[1]) : "", 
        end_time: event.endStr.split("T").length > 1 ? (event.endStr.split("T")[1].split(":")[0] + ":" + event.endStr.split("T")[1].split(":")[1]) : "",
      } 
    }]);

    setSelectedEditLeave(event);
    setSelectedEditLeavePreviousState(info);
  };

  const handleEventDrop = (info) => {
    console.log("HERE");
    const event = info.event;

    setRange([{ 
      startDate: event.start,
      endDate: event.allDay ? subSeconds(event.end, 1) : event.end,
      key: 'selection',
      manual_duration_enabled:!event.allDay, 
      leave_type_times: { 
        start_time: leaveTypes.find((row) => row.id === event.extendedProps.leave_type_id)?.start_time, 
        end_time: leaveTypes.find((row) => row.id === event.extendedProps.leave_type_id)?.end_time 
      }, 
      selected_times: { 
        start_time: event.startStr.split("T").length > 1 ? (event.startStr.split("T")[1].split(":")[0] + ":" + event.startStr.split("T")[1].split(":")[1]) : "", 
        end_time: event.endStr.split("T").length > 1 ? (event.endStr.split("T")[1].split(":")[0] + ":" + event.endStr.split("T")[1].split(":")[1]) : "",
      } 
    }]);

    setSelectedEditLeave(event);
    setSelectedEditLeavePreviousState(info);
  };

  const handleFailedPopupClick = (button) => {
    if (button === "retry"){
      handleRetry();
    }
    else if (button === "OK"){
      setOpenFailedPopup(false);
      if(selectedEditLeavePreviousState){
        selectedEditLeavePreviousState.revert();
      }
      // window.location.reload();
    }
  }

  const handleEventMouseEnter = (info) => {
    const leave_taken = leaveTaken.find(LT_item => LT_item.id === parseInt(info.event.id));

    const tooltip = document.createElement('div');
    tooltip.classList.add('tooltip');
    // tooltip.textContent = info.event.title;
    tooltip.innerHTML = `${format(info.event.start, "MMM d, yyyy")} - ${format(subDays(info.event.end, 1), "MMM d, yyyy")}<br>${leave_taken.status}`;

    tooltip.style.backgroundColor = '#6D6D6D';
    tooltip.style.color = 'white';

    info.el.appendChild(tooltip);
  };

  const handleEventMouseLeave = (info) => {
    // Remove tooltip when mouse leaves an event
    const tooltip = info.el.querySelector('.tooltip');
    if (tooltip) {
      tooltip.parentNode.removeChild(tooltip);
    }
  };  

  // console.log("CALENDAR REF", calendarRef.current.getApi());
  // console.log("CALENDAR REF", calendarRef);

  if (isInitialLoading) {
    return (
      <LoadingOverlay isLoading={isLoading} />
    );
  }

  return (
    <Box m="20px">        
      <Header title="Apply Leave" subtitle="Leave Application Page" />

      <Box display="flex" justifyContent="space-between">
        {/* CALENDAR SIDEBAR */}
        <Box
          flex="1 1 20%"
          backgroundColor={colors.primary[400]}
          p="15px"
          borderRadius="4px"
        >
          <Typography variant="h5">Events</Typography>
          <List>
            {currentEvents.map((event) => (
              <ListItem
                key={event.id}
                sx={{
                  backgroundColor: colors.greenAccent[500],
                  margin: "10px 0",
                  borderRadius: "2px",
                }}
              >
                <ListItemText
                  primary={event.title}
                  secondary={
                    <Typography>
                      {/* {format(event.start, "MMM d, yyyy")} {" - "} {format(subDays(event.end, 1), "MMM d, yyyy")} */}
                      {format(event.start, "MMM d, yyyy") === format(subDays(event.end, 1), "MMM d, yyyy") 
                        ? format(event.start, "MMM d, yyyy") 
                        : `${format(event.start, "MMM d, yyyy")} - ${format(subDays(event.end, 1), "MMM d, yyyy")}`}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>

        {/* CALENDAR */}
        <Box flex="1 1 100%" ml="15px">
          <FullCalendar
            ref={calendarRef} 
            height="75vh"
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin,
            ]}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
            }}
            initialView="dayGridMonth"
            // dragScroll={true}
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            select={handleDateClick}
            eventClick={handleEventClick}
            eventsSet={(events) => setCurrentEvents(events)}
            initialEvents={leaveTaken}
            eventResize={(info) => {
              setIsLoading(true);
              handleEventResize(info);
            }}
            eventDrop={(info) => {
              console.log("ED");
              setIsLoading(true);
              handleEventDrop(info);
            }}
            eventContent={(eventInfo) => {
              const { event } = eventInfo;
              const eventColor = ['approved', 'completed'].includes(leaveTaken.find((item) => item.id === parseInt(event.id))?.status) ? 'green' : leaveTaken.find((item) => item.id === parseInt(event.id))?.status === 'declined' ? 'red' : 'blue'; //(event.id).toString()
              // const eventColor = event.extendedProps.color; // Assuming the color is stored in the extendedProps of the event
          
              return (
                <div
                  style={{
                    backgroundColor: eventColor,
                    color: 'white',
                    borderRadius: '3px',
                    padding: '0px',
                  }}
                >
                  {event.title}
                </div>
              );
            }}
            eventMouseEnter={handleEventMouseEnter}
            eventMouseLeave={handleEventMouseLeave}
            // eventRender={(info) => {
            //   const event = info.event;
            //   const customColor = event.status === 'approved' ? 'green' : 'red';
          
            //   return (
            //     info.render({ backgroundColor: customColor })
            //   );
            // }}
          />
        </Box>
        
      </Box>     {/* <div className="spinner" ></div> */}
        

      <div ref={refLeaveApplicationPopup}>
        {openLeaveApplicationPopup && 
          <Popup 
            openPopup = {openLeaveApplicationPopup}
            setOpenPopup = {setOpenLeaveApplicationPopup}
          >
            <LeaveApplicationForm 
              popupData = {selectedLeave}
            />
          </Popup>
        }
      </div>

      <div ref={refFailedPopup}>
        {openFailedPopup && 
          <Popup 
            openPopup = {openFailedPopup}
            setOpenPopup = {setOpenFailedPopup}
          >
            <Typography style={{ textAlign: 'center' }}>
              Failed 
              <br />
              {/* {console.log(selectedLeave)} */}
              {/* {selectedLeave.event.title} */}
              <br />
              {resultMessage}
            </Typography>
            <Box display="flex" justifyContent="space-between" width="100%" gap="10px" mt="20px">
                <Button onClick={handleRetry} type="button" color="secondary" variant="contained">
                    Retry
                </Button>
                <Button onClick={() => handleFailedPopupClick("OK")} type="button" color="secondary" variant="contained">
                    Ok
                </Button>
            </Box>
          </Popup>
        }
      </div>

      {/* Loading overlay and spinner */}
      <LoadingOverlay isLoading={isLoading} />
      <CustomSnackBar />
    </Box>
  );
};

export default Calendar;
