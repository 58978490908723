import React, { useState, useEffect } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { useStateContext } from "../contexts/ContextProvider";

import { pdfjs } from 'react-pdf';
import { Button } from "@mui/material";

// Specify the worker URL directly
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.worker.min.js`;


// export default function PDFViewer() {
export default function PDFViewer( { pdfUrl } ) {
  console.log(pdfUrl);
    let { setViewPDFViewer } = useStateContext();
// export default function PDFViewer() {
  // const [pdfUrl, setPdfUrl] = useState("");

  // useEffect(() => {
  //   // Replace this URL with the actual link to your PDF file from the backend
  //   // const url = "https://ess-creativehr-backend.creativehr.co.zw/storage/attachments/7_0.pdf";
  //   // const url = "https://smallpdf.com/handle-widget#url=https://assets.ctfassets.net/l3l0sjr15nav/29D2yYGKlHNm0fB2YM1uW4/8e638080a0603252b1a50f35ae8762fd/Get_Started_With_Smallpdf.pdf";
  //   // const url = "http://localhost/reports/TIMESHEET%20SUMMARY_27082024_1325.pdf";
  //   const corsProxy = 'https://cors-anywhere.herokuapp.com/';
  //   const url = `${corsProxy}https://ess-creativehr-backend.creativehr.co.zw/storage/attachments/7_0.pdf`;

  //   setPdfUrl(url);
  // }, []);

  const downloadPDF = async () => {
    try{
        const response = await fetch(pdfUrl);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "timesheet.pdf";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  return (
    <div className="PDFViewer">
      <h1>PDF Preview</h1>
      {pdfUrl && (
        // <Worker workerUrl={pdfjs.GlobalWorkerOptions.workerSrc}>
        // <Worker workerUrl={`https://unpkg.com/pdfjs-dist@2.10.377/build/pdf.worker.min.js`}>
        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
          <Viewer fileUrl={pdfUrl} />
        </Worker>
      )}
      {pdfUrl && (
        <Button onClick={downloadPDF}>Download PDF</Button>
      )}
      {/* cancel button */}
      <Button onClick={() => setViewPDFViewer(false)}>Cancel</Button>
    </div>
  );
}
