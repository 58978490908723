import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import Invoices from "./scenes/invoices";
import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
// import LeaveApplicationForm from "./scenes/leave_application_form/leave_application_form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import Geography from "./scenes/geography";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Calendar from "./scenes/calendar/calendar";
import LoginPage from "./scenes/login_and_registration/login";
import RegistrationPage from "./scenes/login_and_registration/registration";
import BlankPage from "./scenes/login_and_registration/blank";
import { useStateContext } from "./contexts/ContextProvider";
import { verifyTokenAndGetUser } from "./services/userService";
import TimesheetPage from "./scenes/settings/timesheet";
import ReportsPage from "./scenes/settings/reports";
import TimesheetSettingsPage from "./scenes/settings/timesheet_settings";
import SettingsPage from "./scenes/settings/settings";
import ForgotPasswordPage from "./scenes/login_and_registration/forgot_password";
import ResetPasswordPage from "./scenes/login_and_registration/reset_password";
import LoadingOverlay from "./scenes/global/LoadingOverlay";
import ApproveTimesheetsPage from "./scenes/settings/approve_timesheets";
import DashboardPage from "./scenes/dashboard/dashboardPage";

function App() {
  const [theme, colorMode] = useMode();
  const [isLoading, setIsLoading] = useState(true);
  const [isSidebar, setIsSidebar] = useState(true);
  const { token, user, setUser, loggedOut, setLoggedOut } = useStateContext(); 

  useEffect(() => {
    handleTokenVerification(token);
  }, []);

  const handleTokenVerification = async (token) => {//can pass a function as variable to set user if I want to move this method to userService.js
    if (token && !user){ 
      try {
        let result = await verifyTokenAndGetUser(token);
        let status = result.status;

        // console.log("LOGIN TOKEN", result);
  
        // console.log("STATUS", status);
        // console.log("MESSAGE", message);
        if (status !== 200) {
          localStorage.removeItem("token");
          window.location.reload();
          setIsLoading(false);
          return false;
        }
        else {
          setLoggedOut(false);
          setUser(result.data.user)
          setIsLoading(false);
          return true;
        }
      } catch (error) {
        console.log("error", error)
      }
    } else {
      setIsLoading(false);
      return false;
    }
  }

  if (isLoading) {
    return(
      <LoadingOverlay isLoading={isLoading} />
    )
    // return <div>Loading...</div>;
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {(loggedOut) ? 
          <Routes>
            <Route path="/" element={<BlankPage />} />
            <Route path="*" element={<BlankPage />} />
          </Routes>
          :
          (!user) ?
            <Routes>
              <Route path="/" element={<LoginPage />} />
              <Route path="/register" element={<RegistrationPage />} />
              <Route path="/forgot-password" element={<ForgotPasswordPage />} />
              <Route path="/reset-password" element={<ResetPasswordPage />} />
              <Route path="*" element={<LoginPage />} />
            </Routes>
            : 
            //load component to fetch backend data and setStates here
            <div className="app">
              <Sidebar isSidebar={isSidebar} />
              <main className="content">
                <Topbar setIsSidebar={setIsSidebar} />
                <Routes>
                  {/* <Route path="/login" element={<LoginPage />} /> */}
                  {/* <Route path="/register" element={<RegistrationPage />} /> */}
                  <Route path="/" element={<DashboardPage />} />
                  {/* <Route path="/" element={<Dashboard />} /> */}
                  <Route path="/team" element={<Team />} />
                  <Route path="/contacts" element={<Contacts />} />
                  <Route path="/invoices" element={<Invoices />} />
                  <Route path="/form" element={<Form />} />
                  {/* <Route path="/lform" element={<LeaveApplicationForm />} /> */}
                  <Route path="/faq" element={<FAQ />} />
                  <Route path="/leave-planner" element={<Calendar />} />
                  <Route path="/reset-password" element={<ResetPasswordPage />} />
                  {/* admin routes */}
                  {parseInt(parseInt(user.access_level)) !== 3 && (
                    <>
                      <Route path="/approve-timesheets" element={<ApproveTimesheetsPage />} />
                      <Route path="/timesheet-settings" element={<TimesheetSettingsPage />} />
                      <Route path="/timesheet" element={<TimesheetPage />} />
                      <Route path="/reports" element={<ReportsPage />} />
                      <Route path="/bar" element={<Bar />} />
                      <Route path="/pie" element={<Pie />} />
                      <Route path="/line" element={<Line />} />
                      <Route path="/geography" element={<Geography />} />
                      <Route path="/settings" element={<SettingsPage />} />
                    </>
                    )}
                  <Route path="*" element={<DashboardPage />} />
                </Routes>
              </main>
            </div>
        }
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;