import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

const data = [
  { month: 'Jan', payroll: 5000 },
  { month: 'Feb', payroll: 6000 },
  { month: 'Mar', payroll: 7000 },
  { month: 'Apr', payroll: 8000 },
];

const PayrollBarChart = () => (
  <ResponsiveContainer width="100%" height={300}>
    <BarChart data={data}>
      <XAxis dataKey="month" />
      <YAxis />
      <Tooltip />
      <Bar dataKey="payroll" fill="#8884d8" />
    </BarChart>
  </ResponsiveContainer>
);

export default PayrollBarChart;
