import React from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';

const InfoCard = ({ title, value }) => (
  <Card>
    <CardContent>
      <Typography variant="h6" color="textSecondary">
        {title}
      </Typography>
      <Typography variant="h4">
        {value}
      </Typography>
    </CardContent>
  </Card>
);

const InfoCards = () => (
  <Grid container spacing={3}>
    <Grid item xs={12} md={4}>
      <InfoCard title="Total Payroll" value="$15,000" />
    </Grid>
    <Grid item xs={12} md={4}>
      <InfoCard title="Employees" value="150" />
    </Grid>
    <Grid item xs={12} md={4}>
      <InfoCard title="Overtime Hours" value="120" />
    </Grid>
  </Grid>
);

export default InfoCards;
