import { useEffect, useState } from "react";
import { Box, Button, TextField, MenuItem, Tooltip, Typography } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { useStateContext } from "../../contexts/ContextProvider";
import { actionLeaveTaken } from "../../services/leaveService";

import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import DateRangeComp from "../../components/datepickers/DateRangeComp";
import format from 'date-fns/format'
import { addDays } from 'date-fns'

import CircleNumber from "../../components/CircleNumber";
import Snackbar from '@mui/material/Snackbar';

import LoadingOverlay from "../global/LoadingOverlay";




const LeaveApplicationForm = ({ viewedLeave }) => {
  const { user, employees, setOpenLeaveApplicationPopup, setOpenFailedPopup, setSnackbar, setResultMessage, setApproveeLeaveTaken, 
          setLeaveTaken, leaveTypes, isLoading, setIsLoading } = useStateContext();

  const [isHovered, setIsHovered] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(viewedLeave[0].status);


  const isNonMobile = useMediaQuery("(min-width:600px)");
  
  const viewedEmployee = (employees.filter((row) => row.employee_number === viewedLeave[0].employee_number))[0]

  const initialValues = {
    action_reason : viewedLeave[0].action_reason === null ? "" : viewedLeave[0].action_reason,
    status : selectedStatus,
    statusOptions : ["pending", "approved", "declined"]
  };
  
  const handleFormSubmit = async (form_values) => {
    setIsLoading(true);

    const data = {
                  rows: [{...viewedLeave[0], action_reason: form_values.action_reason, status: form_values.status}],
                  editor_employee_number:user.employee_number,
                };

    const action_leave_response = await actionLeaveTaken(data);

    setOpenLeaveApplicationPopup(false);

    if (![200, 202].includes(action_leave_response["status"])){
      setSnackbar({ children: "Error while updating.", severity: 'error' });
      setIsLoading(false);
      setResultMessage(action_leave_response["data"]["message"]);
      setOpenFailedPopup(true);
      return;
    }

    setSnackbar(action_leave_response["status"] === 200 ? { children: 'Leave successfully actioned', severity: 'success' } : { children: 'Leave details changed', severity: 'error' });
    setApproveeLeaveTaken(action_leave_response.data.approvee_leave_taken);
    setLeaveTaken(action_leave_response.data.employee_leave_taken);
    setIsLoading(false);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleStatusSelect = (event, setFieldValue) => {
    setFieldValue(event.target.value)
    setSelectedStatus(event.target.value)
  }

  const enabledOrDisabledButton = (viewedLeave[0].attachment === null) ? true : false;

  return (
    <Box m="20px">
      <Header title={(user.name + " " + user.surname).toUpperCase()} subtitle="Leave Approval" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                id="applicant-name"
                disabled
                fullWidth
                defaultValue={viewedEmployee.name + " " + viewedEmployee.surname}
                type="text"
                label="Applicant"
                name="applicant"
                // error={!!touched.applicant && !!errors.applicant}
                // helperText={touched.applicant && errors.applicant}
                sx={{ gridColumn: "span 4", "& .MuiInputBase-root.Mui-disabled": {fontWeight: "bold", opacity: 1, color: "rgba(0, 0, 0, 0.6)"} }}
              />
              
              <TextField
                id="leave-type"
                disabled
                fullWidth
                defaultValue={leaveTypes.find((row) => row.id === viewedLeave[0].leave_type_id)?.name}
                type="text"
                label="Leave Type"
                name="leave_type"
                sx={{ gridColumn: "span 4", "& input": {fontWeight: "bold", opacity: 1} }}
              />

              <TextField
                id="period"
                disabled
                fullWidth
                defaultValue={format(viewedLeave[0].start_date, "yyyy-MM-dd") + " - " + format(viewedLeave[0].end_date, "yyyy-MM-dd")}
                type="text"
                label="Period"
                name="period"
                sx={{ gridColumn: "span 3", "& input": {fontWeight: "bold", opacity: 1} }}
              />

              <TextField 
                disabled
                fullWidth
                type="text"
                label="Duration"
                value={viewedLeave[0].duration}
                name="duration"
                sx={{ gridColumn: "span 1", "& input": {fontWeight: "bold", opacity: 1} }}
              />

              <TextField
                id="application_reason"
                disabled
                fullWidth
                multiline
                maxRows={4}
                value={viewedLeave[0].application_reason === null ? "" : viewedLeave[0].application_reason}
                type="text"
                label="Application Reason"
                name="application_reason"
                sx={{ gridColumn: "span 4", "& input": {fontWeight: "bold", opacity: 1} }}
              />

            </Box>

            <Box sx={{ mt: 4 }} /> {/* Add spacing between the cards */}

            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(3, minmax(0, 1fr))"
              width="100%"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 3" },
              }}
            >
              <Typography variant="h6" fontWeight="bold" sx={{ gridColumn: "span 3"}}>
                  Attachments
              </Typography>
              
              <Button disabled={enabledOrDisabledButton} onClick={() => window.open(viewedLeave[0].attachment, "_blank")} type="button" color="secondary" variant="contained">
                  Download
              </Button>

              <TextField 
                disabled
                fullWidth
                type="text"
                label="Status"
                value={viewedLeave[0].status}
                name="status"
                sx={{ gridColumn: "span 1", "& input": {fontWeight: "bold", opacity: 1} }}
              />
              
              <TextField 
                disabled
                fullWidth
                type="text"
                label="Actioned By"
                value={viewedLeave[0].actioned_by !== null ? JSON.parse(viewedLeave[0].actioned_by).join(",") : ""}
                name="actioned_by"
                sx={{ gridColumn: "span 1", "& input": {fontWeight: "bold", opacity: 1} }}
              />
            </Box>

            <Box sx={{ mt: 4 }} /> {/* Add spacing between the cards */}

            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                id="action_reason"
                fullWidth
                multiline
                maxRows={4}
                value={values.action_reason}
                type="text"
                label="Action Reason"
                onBlur={handleBlur}
                onChange={(event) => setFieldValue('action_reason', event.target.value)}
                name="action_reason"
                sx={{ gridColumn: "span 4", "& input": {fontWeight: "bold", opacity: 1} }}
              />

              {(parseInt(user.access_level) === 1 || viewedLeave[0].status === "pending") && (
                <TextField
                    id="status"
                    select
                    fullWidth
                    value={selectedStatus}
                    variant="filled"
                    type="text"
                    label="Status"
                    onBlur={handleBlur}
                    onChange={(event) => handleStatusSelect(event, setFieldValue.bind(null, 'status'))}
                    name="status"
                    // error={!!touched.applicant && !!errors.applicant}
                    // helperText={touched.applicant && errors.applicant}
                    sx={{ gridColumn: "span 4" }}
                  >
                    {values.statusOptions.map((action) => (
                      <MenuItem key={action} value={action}>
                        {action}
                        {/* {(applicant.name).toUpperCase()} */}
                      </MenuItem>
                    ))}
                </TextField>
              )}
            </Box>


            <Box sx={{ mt: 4 }} /> {/* Add spacing between the cards */}

            <Box display="flex" justifyContent="space-between" width="100%" gap="10px" mt="20px">
                <Button onClick={() => setOpenLeaveApplicationPopup(false)} type="button" color="secondary" variant="contained">
                    Cancel
                </Button>
                <Button disabled={(viewedLeave[0].status === "pending") ? false : ((parseInt(user.access_level) === 1) ? false : true)} 
                        type="submit" color="secondary" variant="contained">
                    Action
                </Button>
            </Box>
          </form>
        )}
      </Formik>

      <LoadingOverlay isLoading={isLoading} />
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  // leave_type: yup.string().required("required"),
  // reason: yup.string().required("required"),
  // attatchment: yup.string().required("required"),
  // email: yup.string().email("invalid email").required("required"),
  // contact: yup
  //   .string()
  //   .matches(phoneRegExp, "Phone number is not valid")
  //   .required("required"),
  // address1: yup.string().required("required"),
  // address2: yup.string().required("required"),
});

export default LeaveApplicationForm;
