import * as React from 'react';
import { useState, useRef, useEffect } from 'react';
import { Box, Button, Typography, CircularProgress, Select as MuiSelect, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useStateContext } from "../../contexts/ContextProvider";
import { getApproveeLeaveTaken, actionLeaveTaken, getLeavePlannerData, buildModifiedTimesheetData,
  generateMonthList, buildInitialCombinedApprovalData, getTimesheetData, actionTimesheet
} from "../../services/leaveService";
import CustomDataGrid from '../../components/CustomDataGrid';
import CollapsableCard from '../../components/CollapsableCard';
import Popup from "../../components/Popup";
import ReportExporterForm from './report_exporter_form';
import Select from 'react-select';
import moment from 'moment';
import LoadingOverlay from '../global/LoadingOverlay';
import * as XLSX from 'xlsx';
import CustomSnackBar from '../../components/CustomSnackBar';
// import FormControl from '@mui/material';
// import InputLabel from '@mui/material';
// import MenuItem from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { number } from 'yup';
import { styled } from '@mui/system';
import DataFilter from '../global/DataFilter';

import PDFViewer from '../../components/PDFViewerAndDownloader';


const ReportsPage = () => {
  let { user, employees, setEmployees, openLeaveApplicationPopup, setOpenLeaveApplicationPopup, 
        openFailedPopup, setOpenFailedPopup, resultMessage, isLoading, setIsLoading, setSnackbar,
        timesheetData, timesheetSettings, months, setTimesheetData, setTimesheetSettings, setMonths, jobs, setJobs,
        combinedApprovalData, setCombinedApprovalData, parmCodes, setParmCodes, dayTypes, setDayTypes, timesheetApprovers, setTimesheetApprovers,
        payrollUsers, setPayrollUsers, payrollRights, setPayrollRights, initialEmployees, setInitialEmployees,
        allPayrolls, setAllPayrolls, allPayPoints, setAllPayPoints, allCostCodes, setAllCostCodes, modifiedApproveTimesheetData, setModifiedApproveTimesheetData,
        selectedPayroll, setSelectedPayroll, selectedPayPoint, setSelectedPayPoint, selectedCostCode, setSelectedCostCode,
        availablePayrolls, setAvailablePayrolls, availablePayPoints, setAvailablePayPoints, availableCostCodes, setAvailableCostCodes,
        selectedTimekeeper, setSelectedTimekeeper, selectedTimesheetData, setSelectedTimesheetData, selectedMonth, setSelectedMonth, monthList,
        pdfUrl, viewPDFViewer, setViewPDFViewer, availableReports, setAvailableReports, selectedReport, setSelectedReport
      } = useStateContext(); 

  const refPopup = useRef(null);
  const refFailedPopup = useRef(null);
  const refPDFViewerPopup = useRef(null);

  useEffect(() => {
    const fetchLeaveValues = async () => {
      setIsLoading(true);

      if(employees.length === 0){
        const data = await getTimesheetData(user);
        const emps = data.employees;
        const timesheet_data = data.timesheet_data;
        let parm_codes = data.parm_codes;
        parm_codes = parm_codes.filter(code => code.currency);
        const timesheet_settings = data.timesheet_settings;
        const day_types = data.day_types;
        const jobs_fetched = data.jobs;
        const timesheet_approvers = data.timesheet_approvers;
        const payroll_users = data.payroll_users;
        const payroll_rights = data.payroll_rights;
        const available_payrolls = data.available_payrolls;
        const available_paypoints = data.available_paypoints;
        const available_costcodes = data.available_costcodes;
        const all_payrolls = data.all_payrolls;
        const all_paypoints = data.all_paypoints;
        const all_costcodes = data.all_costcodes;
        const available_reports = data.available_reports

        setAvailableReports(available_reports);
        setSelectedTimekeeper(payroll_users[0]);
        setSelectedTimesheetData(timesheet_data);
        setInitialEmployees(emps);

        setAllPayrolls(all_payrolls);
        setAllPayPoints(all_paypoints);
        setAllCostCodes(all_costcodes);        
        setSelectedPayroll(available_payrolls[0]);
        setSelectedPayPoint(available_paypoints[0]);
        setSelectedCostCode(available_costcodes[0]);
        setAvailablePayrolls(available_payrolls);
        setAvailablePayPoints(available_paypoints);
        setAvailableCostCodes(available_costcodes);
        setPayrollRights(payroll_rights);        
        setPayrollUsers(payroll_users);          
        setTimesheetApprovers(timesheet_approvers);
        setEmployees(emps);
        setTimesheetData(timesheet_data);
        setParmCodes(parm_codes);
        setTimesheetSettings(timesheet_settings);
        setDayTypes(day_types);
        setJobs(jobs_fetched);

        // monthList = generateMonthList(2024, selectedMonth.number);
        const initialCombinedApprovalData = await buildInitialCombinedApprovalData(monthList, timesheet_data, timesheet_settings);
        setCombinedApprovalData(initialCombinedApprovalData);
      } else {
        setSelectedTimekeeper(payrollUsers[0]);
        setInitialEmployees(employees);

        employees.forEach(employee => {
          availableEmpNos.push(employee.EmpNo);
        });
  
        const timesheet_data = timesheetData.filter(data => (parseInt(data.captured_by) === parseInt(payrollUsers[0].id)) & (availableEmpNos.includes(data.EmpNo)));
        setSelectedTimesheetData(timesheet_data);
  
        const initialCombinedApprovalData = await buildInitialCombinedApprovalData(monthList, timesheet_data, timesheetSettings);
        setCombinedApprovalData(initialCombinedApprovalData);
      }


      setIsLoading(false);
      // setIsInitialLoading(false);
    };
    
    // initialEvents = fetchLeaveValues();
    fetchLeaveValues();
  }, []);


  const handleRetry = () => {
    setOpenFailedPopup(false);
    setOpenLeaveApplicationPopup(true);
  };

  const handleFailedPopupClick = (button) => {
    if (button === "retry"){
      handleRetry();
    }
    else if (button === "OK"){
      setOpenFailedPopup(false);

      window.location.reload();
    }
  };

  const replaceKeys = (obj, mapping) => {
    return Object.keys(obj).reduce((acc, key) => {
      const newKey = mapping[key] || key;
      acc[newKey] = ["yes", "Yes", 1, "1", true, "True", "true"].includes(obj[key]) ? 1 : ["no", "No", 0, "0", false, "False", "false"].includes(obj[key]) ? 0 : obj[key];
      return acc;
    }, {});
  };

  const convertFloatToTime = (time) => {
    time = parseFloat(time) * 24;
    const integerPart = Math.floor(time);
    const decimalPart = time - integerPart;

    const hour = integerPart.toString().padStart(2, '0');
    const minute = Math.round(decimalPart * 60).toString().padStart(2, '0');

    return `${hour}:${minute}`;
  };

  // const handleSelectMonth = async (event) => {
  //   setSelectedMonth(event);
  //   const newMonthList = generateMonthList(2024, event.number);
  //   setMonthList(newMonthList);
  //   setSelectedDate(`2024-${event.number.toString().padStart(2, '0')}-01`);
    
  //   const newData = await buildInitialCombinedApprovalData(newMonthList, timesheetData, timesheetSettings);
  //   setCombinedApprovalData(newData);
  // };


  const handleViewClick = async (row) => {
    // console.log(row);
    // const newMonthList = generateMonthList(2024, (row.day).split("-")[1]);
    // const modifiedViewData = await buildModifiedTimesheetData("approve", newMonthList.find(item => item.date === row.day), timesheetData, employees, timesheetSettings, dayTypes, jobs);
    // setModifiedApproveTimesheetData(modifiedViewData);

    setSelectedReport(row.report_name);
    setOpenLeaveApplicationPopup(true);
    // setSelectedDate(row.day);
  }  

  const handleSelectChange = async (value, date) => {
    setIsLoading(true);
    // console.log(value);
    // console.log(event.target.value);
    // console.log(date);
    // return;

    // console.log(modifiedApproveTimesheetData);

    const data = selectedTimesheetData.filter(data => data.date === date);
    // change all data approval_status to approved
    data.forEach((dict) => {
      // console.log(dict["approval_status"]);
      dict["approval_status"] = modifiedApproveTimesheetData.find(item => item.EmpNo === dict.EmpNo).action_to_send;
      // dict["approval_status"] = dict["approval_status"] !== "approved" ? dict["approval_status"] : value;
    });

    console.log(data);

    const timesheet_data = {
      timesheet_data: data,
      payroll:  selectedPayroll,
      paypoint: selectedPayPoint,
      costcode: selectedCostCode,
    }

    // console.log(timesheet_data);

    const response = await actionTimesheet(timesheet_data);

    // console.log(response);

    setTimesheetData(response.data.timesheet_data);
    // console.log(newData);

    //rebuilding combinedTimesheetData
    // console.log(monthList);
    const initialCombinedApprovalData = await buildInitialCombinedApprovalData(monthList, response.data.timesheet_data, timesheetSettings);
    // console.log(initialCombinedApprovalData);

    setCombinedApprovalData(initialCombinedApprovalData);

    setSnackbar({ children: response.data.message, severity: 'success' });

    setOpenLeaveApplicationPopup(false);
    setIsLoading(false);
  };


  const ButtonStyledSelect = styled(MuiSelect)(({ theme }) => ({
    // '&.MuiInputBase-root': {
    //   borderRadius: theme.shape.borderRadius,
    //   backgroundColor: theme.palette.primary.main,
    //   color: theme.palette.primary.contrastText,
    //   padding: theme.spacing(1, 2),
    //   '&:hover': {
    //     backgroundColor: theme.palette.primary.dark,
    //   },
    //   '& .MuiSelect-icon': {
    //     color: theme.palette.primary.contrastText,
    //   },
    // },
  }));


  // if (isLoading || employees.length === 0 || !combinedApprovalData.length === 0) {
  //   return null;
  //   // return <CircularProgress />;
  // }

  // setSelectedEmployee(1);
  // console.log(selectedEmployee);
  // console.log(employees);

  let availableEmpNos = [];
    employees.forEach(employee => {
      availableEmpNos.push(employee.EmpNo);
  });

  let allJobs = [];
    jobs.forEach(code => {
      allJobs.push(`${code.name}`);
  });

  let allDayTypes = [];
    dayTypes.forEach(code => {
      allDayTypes.push(`${code.name}`);
  });

  // let allPayrollUsers = [];
  //   payrollUsers.forEach(code => {
  //     allPayrollUsers.push(`${code.id}`);
  // });

  // APPROVAL GRID


  // setCombinedApprovalData(combinedApprovalDataInitial);

  // console.log(combinedApprovalData);
  // return;

  //APPROVAL TIMESHEET
  const timesheetColumns = [
    { field: 'report_name', headerName: 'Report Name', width: 600 },
  ].concat([
    { field: 'view', headerName: 'View', width: 600, 
      renderCell: ({ row }) => (
      // renderCell: ({ value, id, api }) => (
        <Button 
          disabled={ false }
          onClick={() => handleViewClick(row)} 
          type="button" 
          color="secondary" 
          variant="contained"
        >
          Export
        </Button>
      )
    },
]);

  const addApprovalDict = { };

  // VIEW TIMESHEET GRID
  // DAY VIEW - MODIFIED TIMESHEET GRID


  // console.log(modifiedApproveTimesheetData);


  return (
    <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      {/* // :DROP DOWNS TO SELECT MONTH, TIMEKEEPER, PAYROLL, PAYPOINT, COSTCODE */}
      <DataFilter month={true} timekeeper={true} payroll={true} paypoint={true} costcode={true} />

      <Box sx={{ mt: 2 }} />
      
      <input id="xl-file-input" type="file" accept=".xlsx" style={{ display: 'none' }} /> 

      <CollapsableCard 
        cardHeader={`EXTRACT REPORTS`}
        // cardHeader={`${(selectedMonth.name).toUpperCase()} TIMESHEET - ${(selectedEmployee.name).toUpperCase()} ${(selectedEmployee.surname).toUpperCase()}`}
        expanded={true}
        cardContent={
          <CustomDataGrid 
          rows={availableReports} 
          setRows={setAvailableReports} 
          otherColumns={[timesheetColumns, addApprovalDict]} 
          backendCRUD = {{
            "C": "",
            "R": "",
            "U": "",
            "D": "",
            "TYPE": "LEAVE TYPE",
          }}
          actions={false}
          extraButtons={[
          ]}
        />
        }
      />

      <Box sx={{ mt: 2 }} /> {/* Add spacing between the cards */}

      


      <div ref={refPopup}>
        {openLeaveApplicationPopup && 
          <Popup 
            openPopup = {openLeaveApplicationPopup}
            setOpenPopup = {setOpenLeaveApplicationPopup}
          >
            <ReportExporterForm 
              viewedLeave = {{}}
            />
          </Popup>
        }

      </div>
      
      <div ref={refPDFViewerPopup}>
        { viewPDFViewer && 
          <Popup 
            openPopup = {viewPDFViewer}
            setOpenPopup = {setViewPDFViewer}
          >
            <PDFViewer pdfUrl={pdfUrl} />
          </Popup>
          // onClose={() => setViewPDFViewer(false)} />
        }
      </div>

      <div ref={refFailedPopup}>
        {openFailedPopup && 
          <Popup 
            openPopup = {openFailedPopup}
            setOpenPopup = {setOpenFailedPopup}
          >
            <Typography style={{ textAlign: 'center' }}>
              Failed 
              <br />
              {/* {console.log(selectedLeave)} */}
              {/* {selectedLeave.event.title} */}
              <br />
              {resultMessage}
            </Typography>
            <Box display="flex" justifyContent="space-between" width="100%" gap="10px" mt="20px">
                <Button onClick={handleRetry} type="button" color="secondary" variant="contained">
                    Retry
                </Button>
                <Button onClick={() => handleFailedPopupClick("OK")} type="button" color="secondary" variant="contained">
                    Ok
                </Button>
            </Box>
          </Popup>
        }
      </div>

      {/* <LoadingOverlay isLoading={isLoading} /> */}

    </Box>
  );  
};






export default ReportsPage;