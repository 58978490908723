import React, { createContext, useContext, useState } from 'react';

const StateContext = createContext();

const initialState = {
  chat: false,
  cart: false,
  userProfile: false,
  notification: false,
};

const monthsList = [
  { number: 1, name: 'January' },
  { number: 2, name: 'February' },
  { number: 3, name: 'March' },
  { number: 4, name: 'April' },
  { number: 5, name: 'May' },
  { number: 6, name: 'June' },
  { number: 7, name: 'July' },
  { number: 8, name: 'August' },
  { number: 9, name: 'September' },
  { number: 10, name: 'October' },
  { number: 11, name: 'November' },
  { number: 12, name: 'December' },
]

const timesheetSettingsList = [
  { "id": 1, "day_type_id": 1, "job_id": 1, "value_ordinal": 55, "amt_ordinal": 56, "rate":2.85 },
  { "id": 2, "day_type_id": 2, "job_id": 1, "value_ordinal": 55, "amt_ordinal": 56, "rate":2.85 },
  { "id": 3, "day_type_id": 3, "job_id": 1, "value_ordinal": 55, "amt_ordinal": 56, "rate":2.85 },
  { "id": 4, "day_type_id": 4, "job_id": 1, "value_ordinal": 55, "amt_ordinal": 56, "rate":2.85 },
  { "id": 5, "day_type_id": 5, "job_id": 1, "value_ordinal": 55, "amt_ordinal": 56, "rate":2.85 },
  { "id": 6, "day_type_id": 6, "job_id": 1, "value_ordinal": 55, "amt_ordinal": 56, "rate":2.85 },
  { "id": 7, "day_type_id": 7, "job_id": 1, "value_ordinal": 55, "amt_ordinal": 56, "rate":2.85 },
  { "id": 8, "day_type_id": 8, "job_id": 1, "value_ordinal": 55, "amt_ordinal": 56, "rate":2.85 },
  { "id": 9, "day_type_id": 9, "job_id": 1, "value_ordinal": 55, "amt_ordinal": 56, "rate":2.85 },
  { "id": 10, "day_type_id": 1, "job_id": 2, "value_ordinal": 55, "amt_ordinal": 56, "rate":2.85 },
  { "id": 11, "day_type_id": 2, "job_id": 2, "value_ordinal": 55, "amt_ordinal": 56, "rate":2.85 },
  { "id": 12, "day_type_id": 3, "job_id": 2, "value_ordinal": 55, "amt_ordinal": 56, "rate":2.85 },
  { "id": 13, "day_type_id": 4, "job_id": 2, "value_ordinal": 55, "amt_ordinal": 56, "rate":2.85 },
  { "id": 14, "day_type_id": 5, "job_id": 2, "value_ordinal": 55, "amt_ordinal": 56, "rate":2.85 },
  { "id": 15, "day_type_id": 6, "job_id": 2, "value_ordinal": 55, "amt_ordinal": 56, "rate":2.85 },
  { "id": 16, "day_type_id": 7, "job_id": 2, "value_ordinal": 55, "amt_ordinal": 56, "rate":2.85 },
  { "id": 17, "day_type_id": 8, "job_id": 2, "value_ordinal": 55, "amt_ordinal": 56, "rate":2.85 },
  { "id": 18, "day_type_id": 9, "job_id": 2, "value_ordinal": 55, "amt_ordinal": 56, "rate":2.85 },
  { "id": 19, "day_type_id": 1, "job_id": 3, "value_ordinal": 55, "amt_ordinal": 56, "rate":2.85 },
  { "id": 20, "day_type_id": 2, "job_id": 3, "value_ordinal": 55, "amt_ordinal": 56, "rate":2.85 },
  { "id": 21, "day_type_id": 3, "job_id": 3, "value_ordinal": 55, "amt_ordinal": 56, "rate":2.85 },
  { "id": 22, "day_type_id": 4, "job_id": 3, "value_ordinal": 55, "amt_ordinal": 56, "rate":2.85 },
  { "id": 23, "day_type_id": 5, "job_id": 3, "value_ordinal": 55, "amt_ordinal": 56, "rate":2.85 },
  { "id": 24, "day_type_id": 6, "job_id": 3, "value_ordinal": 55, "amt_ordinal": 56, "rate":2.85 },
  { "id": 25, "day_type_id": 7, "job_id": 3, "value_ordinal": 55, "amt_ordinal": 56, "rate":2.85 },
  { "id": 26, "day_type_id": 8, "job_id": 3, "value_ordinal": 55, "amt_ordinal": 56, "rate":2.85 },
  { "id": 27, "day_type_id": 9, "job_id": 3, "value_ordinal": 55, "amt_ordinal": 56, "rate":2.85 },
  { "id": 28, "day_type_id": 1, "job_id": 4, "value_ordinal": 55, "amt_ordinal": 56, "rate":2.85 },
  { "id": 29, "day_type_id": 2, "job_id": 4, "value_ordinal": 55, "amt_ordinal": 56, "rate":2.85 },
  { "id": 30, "day_type_id": 3, "job_id": 4, "value_ordinal": 55, "amt_ordinal": 56, "rate":2.85 },
  { "id": 31, "day_type_id": 4, "job_id": 4, "value_ordinal": 55, "amt_ordinal": 56, "rate":2.85 },
  { "id": 32, "day_type_id": 5, "job_id": 4, "value_ordinal": 55, "amt_ordinal": 56, "rate":2.85 },
  { "id": 33, "day_type_id": 6, "job_id": 4, "value_ordinal": 55, "amt_ordinal": 56, "rate":2.85 },
  { "id": 34, "day_type_id": 7, "job_id": 4, "value_ordinal": 55, "amt_ordinal": 56, "rate":2.85 },
  { "id": 35, "day_type_id": 8, "job_id": 4, "value_ordinal": 55, "amt_ordinal": 56, "rate":2.85 },
  { "id": 36, "day_type_id": 9, "job_id": 4, "value_ordinal": 55, "amt_ordinal": 56, "rate":2.85 },
  { "id": 37, "day_type_id": 1, "job_id": 5, "value_ordinal": 55, "amt_ordinal": 56, "rate":2.85 },
  { "id": 38, "day_type_id": 2, "job_id": 5, "value_ordinal": 55, "amt_ordinal": 56, "rate":2.85 },
  { "id": 39, "day_type_id": 3, "job_id": 5, "value_ordinal": 55, "amt_ordinal": 56, "rate":2.85 },
  { "id": 40, "day_type_id": 4, "job_id": 5, "value_ordinal": 55, "amt_ordinal": 56, "rate":2.85 },
  { "id": 41, "day_type_id": 5, "job_id": 5, "value_ordinal": 55, "amt_ordinal": 56, "rate":2.85 },
  { "id": 42, "day_type_id": 6, "job_id": 5, "value_ordinal": 55, "amt_ordinal": 56, "rate":2.85 },
  { "id": 43, "day_type_id": 7, "job_id": 5, "value_ordinal": 55, "amt_ordinal": 56, "rate":2.85 },
  { "id": 44, "day_type_id": 8, "job_id": 5, "value_ordinal": 55, "amt_ordinal": 56, "rate":2.85 },
  { "id": 45, "day_type_id": 9, "job_id": 5, "value_ordinal": 55, "amt_ordinal": 56, "rate":2.85 },
];

const dayTypesList = [
  { "id": 1, "name": "Normal Days"},
  { "id": 2, "name": "Sundays"},
  { "id": 3, "name": "Holidays"},
  { "id": 4, "name": "Overtime hours"},
  { "id": 5, "name": "Overtime other"},
  { "id": 6, "name": "Off"},
  { "id": 7, "name": "Sick Leave"},
  { "id": 8, "name": "Vacation Leave"},
  { "id": 9, "name": "Compasionate Leave"},
]

const jobsList = [
  { "id": 1, "name": "Digging" },
  { "id": 2, "name": "Harvesting" },
  { "id": 3, "name": "Driving" },
  { "id": 4, "name": "Weeding" },
  { "id": 5, "name": "Eating" },
]

const timesheetDataList = [
  { "id": 1, "EmpNo": 7, "date": "2024-07-01", "day_type_id": 1, "value":1, "job_id": 1, "approval_status":"pending" },
  { "id": 2, "EmpNo": 6, "date": "2024-07-01", "day_type_id": 4, "value":1, "job_id": 1, "approval_status":"pending" },
  { "id": 3, "EmpNo": 6, "date": "2024-07-02", "day_type_id": 1, "value":0.5, "job_id": 1, "approval_status":"pending" },
  { "id": 4, "EmpNo": 8, "date": "2024-07-02", "day_type_id": 1, "value":0.5, "job_id": 2, "approval_status":"pending" },
  { "id": 5, "EmpNo": 8, "date": "2024-07-03", "day_type_id": 4, "value":1, "job_id": 1, "approval_status":"pending" },
]

const parmCodesList = [
  {
    "id": 1,
    "AdjustFlag": "",
    "CodeName": "T-Hrs O/T @ 2.5",
    "CodeType": "T",
    "ConsolArnFlag": "",
    "ConsolCode": 54,
    "Limit": 9999999,
    "ManualDisplayInd": "",
    "OrdinalNo": 54,
    "Payroll": 1,
    "ProRataInd": "",
    "QmfDisplayInd": "",
    "TaxIncAsn": "N",
    "TaxType": "N",
    "currency": "",
    "TamsColumn": "",
    "Journal": ""
  },
  {
    "id": 2,
    "AdjustFlag": "",
    "CodeName": "EU-Pay O/T @ 2.5",
    "CodeType": "E",
    "ConsolArnFlag": "",
    "ConsolCode": 55,
    "Limit": 9999999,
    "ManualDisplayInd": "",
    "OrdinalNo": 55,
    "Payroll": 1,
    "ProRataInd": "",
    "QmfDisplayInd": "",
    "TaxIncAsn": "N",
    "TaxType": "N",
    "currency": "U",
    "TamsColumn": "Overtime",
    "Journal": ""
  },
  {
    "id": 3,
    "AdjustFlag": "A",
    "CodeName": "T-Hrs O/T @ 1.5",
    "CodeType": "T",
    "ConsolArnFlag": "A",
    "ConsolCode": 56,
    "Limit": 999,
    "ManualDisplayInd": "Y",
    "OrdinalNo": 56,
    "Payroll": 1,
    "ProRataInd": "N",
    "QmfDisplayInd": "N",
    "TaxIncAsn": "N",
    "TaxType": "N",
    "currency": "Z",
    "TamsColumn": "",
    "Journal": ""
  },
  {
    "id": 4,
    "AdjustFlag": "A",
    "CodeName": "EU-Pay O/T @ 1.5",
    "CodeType": "E",
    "ConsolArnFlag": "A",
    "ConsolCode": 57,
    "Limit": 99999,
    "ManualDisplayInd": "Y",
    "OrdinalNo": 57,
    "Payroll": 1,
    "ProRataInd": "N",
    "QmfDisplayInd": "N",
    "TaxIncAsn": "A",
    "TaxType": "N",
    "currency": "U",
    "TamsColumn": "Overtime",
    "Journal": ""
  },
  {
    "id": 5,
    "AdjustFlag": "A",
    "CodeName": "T-Hrs O/T @ 2.0",
    "CodeType": "T",
    "ConsolArnFlag": "A",
    "ConsolCode": 58,
    "Limit": 999,
    "ManualDisplayInd": "Y",
    "OrdinalNo": 58,
    "Payroll": 1,
    "ProRataInd": "N",
    "QmfDisplayInd": "N",
    "TaxIncAsn": "N",
    "TaxType": "N",
    "currency": "Z",
    "TamsColumn": "",
    "Journal": ""
  },
  {
    "id": 6,
    "AdjustFlag": "A",
    "CodeName": "EU-Pay O/T @ 2.0",
    "CodeType": "E",
    "ConsolArnFlag": "A",
    "ConsolCode": 59,
    "Limit": 99999,
    "ManualDisplayInd": "Y",
    "OrdinalNo": 59,
    "Payroll": 1,
    "ProRataInd": "N",
    "QmfDisplayInd": "N",
    "TaxIncAsn": "A",
    "TaxType": "N",
    "currency": "U",
    "TamsColumn": "Overtime",
    "Journal": ""
  },
]

const timesheetApproversList = [
  { "id": 1, "payroll_id": 1, "approval_order":"[0]", "approval_type":"any" },
]

const availablePayrollsList = [
  { "id": 1, "payroll_name": "DCA_EXECUTIVE" },
]


export const ContextProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [user, setUser] = useState(null);
  const [beforeLoginMessage, setBeforeLoginMessage] = useState(null);
  const [loggedOut, setLoggedOut] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [snackbar, setSnackbar] = useState(null);
  const [backdropOpen, setBackdropOpen] = useState(false);

  const [employees, setEmployees] = useState([]);
  const [initialEmployees, setInitialEmployees] = useState([]);
  const [allLeaveTaken, setAllLeaveTaken] = useState([]);
  const [approveeLeaveTaken, setApproveeLeaveTaken] = useState([]);
  const [leaveTaken, setLeaveTaken] = useState([]);
  const [leaveBalances, setLeaveBalances] = useState([]);
  const [selectedLeave, setSelectedLeave] = useState(null);
  const [selectedEditLeave, setSelectedEditLeave] = useState(null);
  const [selectedLeaveType, setSelectedLeaveType] = useState(1);
  const [leaveGroups, setLeaveGroups] = useState([]);
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [publicHolidays, setPublicHolidays] = useState([]);
  const [accrualRuns, setAccrualRuns] = useState([]);
  const [screenSize, setScreenSize] = useState(undefined);
  const [currentColor, setCurrentColor] = useState('#03C9D7');
  const [currentMode, setCurrentMode] = useState('Light');
  const [themeSettings, setThemeSettings] = useState(false);
  const [activeMenu, setActiveMenu] = useState(true);
  const [isClicked, setIsClicked] = useState(initialState);

  const [currentEvents, setCurrentEvents] = useState([]);
  const [openLeaveApplicationPopup, setOpenLeaveApplicationPopup] = useState(false);
  const [openFailedPopup, setOpenFailedPopup] = useState(false);
  const [openCalendarPopup, setOpenCalendarPopup] = useState(false);
  const [openDeleteEventPopup, setOpenDeleteEventPopup] = useState(false);

  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const [inputUploadType, setInputUploadType] = useState("SQL")
  
  const [duration, setDuration] = useState(0);
  const [resultMessage, setResultMessage] = useState("");
  
  // date state
  const todays_date = new Date();
  const monthName = todays_date.toLocaleString('default', { month: 'long' });
  const dayName = todays_date.toLocaleString('default', { weekday: 'short' });
  const todays_date_string = todays_date.toISOString().split('T')[0];
  const year = todays_date_string.split('-')[0];
  const month = todays_date_string.split('-')[1];
  const day = todays_date_string.split('-')[2];

  const [range, setRange] = useState([
    {
      startDate: new Date(year, month-1, day, 12, 17, 49),
      endDate: new Date(year, month-1, day, 12, 17, 49),
      // todays year 
      // todayDate: new Date()
      key: 'selection',
      manual_duration_enabled:false, 
      leave_type_times: { start_time: "", end_time: "" }, 
      selected_times: { start_time: "", end_time: "" } 
    }
  ]);
  //

  const [dbBackups, setDBBackups] = useState([]);

  const [months, setMonths] = useState(monthsList);
  const [timesheetSettings, setTimesheetSettings] = useState(timesheetSettingsList);
  const [timesheetData, setTimesheetData] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [parmCodes, setParmCodes] = useState([]);
  const [employeeDefaults, setEmployeeDefaults] = useState([])
  const [timesheetApprovers, setTimesheetApprovers] = useState([]);
  const [dayTypes, setDayTypes] = useState([]);
  const [modifiedTimesheetData, setModifiedTimesheetData] = useState([]);
  const [modifiedApproveTimesheetData, setModifiedApproveTimesheetData] = useState([]);
  const [combinedApprovalData, setCombinedApprovalData] = useState([]);
  const [payrollUsers, setPayrollUsers] = useState([]);
  const [payrollRights, setPayrollRights] = useState([]);
  const [allPayrolls, setAllPayrolls] = useState([]);
  const [allPayPoints, setAllPayPoints] = useState([]);
  const [allCostCodes, setAllCostCodes] = useState([]);
  const [selectedPayroll, setSelectedPayroll] = useState(null);
  const [selectedPayPoint, setSelectedPayPoint] = useState(null);
  const [selectedCostCode, setSelectedCostCode] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [availablePayrolls, setAvailablePayrolls] = useState([]);
  const [availablePayPoints, setAvailablePayPoints] = useState([]);
  const [availableCostCodes, setAvailableCostCodes] = useState([]);
  const [selectedTimekeeper, setSelectedTimekeeper] = useState([]);
  const [selectedTimesheetData, setSelectedTimesheetData] = useState([]);
  const [selectedDay, setSelectedDay] = useState({day:parseInt(day), date:todays_date_string, day_name:dayName});
  const [selectedMonth, setSelectedMonth] = useState({number:parseInt(month), name:monthName});
  const [monthList, setMonthList] = useState([]);
  
  // FOR TIMESHEET PAGE
  const [dayIndex, setDayIndex] = useState(0);
  const [disablePreviousButton, setDisablePreviousButton] = useState(true);
  const [disableNextButton, setDisableNextButton] = useState(false);
  //

  const [viewPDFViewer, setViewPDFViewer] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);

  const [availableReports, setAvailableReports] = useState([]);
  const [selectedReport, setSelectedReport] = useState("");



  





  const setMode = (e) => {
    setCurrentMode(e.target.value);
    localStorage.setItem('themeMode', e.target.value);
    setThemeSettings(false);
  };

  const setColor = (color) => {
    setCurrentColor(color);
    localStorage.setItem('colorMode', color);
    setThemeSettings(false);
  };

  const handleClick = (clicked) => setIsClicked({ ...initialState, [clicked]: true });

  // hide dropdown on ESC press
  const hideOnEscape = (e) => {
    // console.log(e.key)
    if( e.key === "Escape" ) {
      setOpenLeaveApplicationPopup(false)
    }
  }

  // Hide on outside click
  const hideOnClickOutside = (ref) => (e) => {
    // console.log(ref.current)
    // console.log(e.target)
    if( ref.current && ! ref.current.contains(e.target) ) {
      setOpenLeaveApplicationPopup(false)
    }
  }

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <StateContext.Provider 
        value={{ token, setToken, user, setUser, beforeLoginMessage, setBeforeLoginMessage, 
                leaveBalances, setLeaveBalances, employees, setEmployees, initialEmployees, setInitialEmployees,
                loggedOut, setLoggedOut,
                isLoading, setIsLoading, snackbar, setSnackbar, backdropOpen, setBackdropOpen,
                currentColor, currentMode, activeMenu, screenSize, allLeaveTaken, setAllLeaveTaken,
                approveeLeaveTaken, setApproveeLeaveTaken, leaveTaken, setLeaveTaken, leaveTypes, setLeaveTypes,
                selectedLeaveType, setSelectedLeaveType, leaveGroups, setLeaveGroups, publicHolidays, setPublicHolidays,
                selectedLeave, setSelectedLeave, selectedEditLeave, setSelectedEditLeave, 
                setScreenSize, handleClick, isClicked, initialState, 
                setIsClicked, setActiveMenu, setCurrentColor, setCurrentMode, 
                setMode, setColor, themeSettings, setThemeSettings,
                currentEvents, setCurrentEvents, openFailedPopup, setOpenFailedPopup, 
                openLeaveApplicationPopup, setOpenLeaveApplicationPopup, accrualRuns, setAccrualRuns,
                openCalendarPopup, setOpenCalendarPopup, openDeleteEventPopup, setOpenDeleteEventPopup, 
                hideOnEscape, hideOnClickOutside, range, setRange, 
                duration, setDuration, resultMessage, setResultMessage,
                dbBackups, setDBBackups, maintenanceMode, setMaintenanceMode, selectedEmployee, setSelectedEmployee,
                inputUploadType, setInputUploadType, modifiedApproveTimesheetData, setModifiedApproveTimesheetData,
                timesheetData, timesheetSettings, months, setTimesheetData, setTimesheetSettings, setMonths,
                jobs, setJobs, parmCodes, setParmCodes, timesheetApprovers, setTimesheetApprovers,
                availablePayrolls, setAvailablePayrolls, dayTypes, setDayTypes, modifiedTimesheetData, setModifiedTimesheetData,
                combinedApprovalData, setCombinedApprovalData, payrollUsers, setPayrollUsers, payrollRights, setPayrollRights,
                allPayrolls, setAllPayrolls, allPayPoints, setAllPayPoints, allCostCodes, setAllCostCodes,
                selectedPayroll, setSelectedPayroll, selectedPayPoint, setSelectedPayPoint, selectedCostCode, setSelectedCostCode,
                availablePayrolls, setAvailablePayrolls, availablePayPoints, setAvailablePayPoints, availableCostCodes, setAvailableCostCodes,
                selectedTimekeeper, setSelectedTimekeeper, selectedTimesheetData, setSelectedTimesheetData,
                selectedDay, setSelectedDay, selectedMonth, setSelectedMonth, monthList, setMonthList, 
                dayIndex, setDayIndex, disablePreviousButton, setDisablePreviousButton, disableNextButton, setDisableNextButton,
                viewPDFViewer, setViewPDFViewer, pdfUrl, setPdfUrl, availableReports, setAvailableReports, selectedActivity, setSelectedActivity,
                selectedReport, setSelectedReport, employeeDefaults, setEmployeeDefaults
               }} //manualDuration, setManualDuration,
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);