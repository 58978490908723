import React from 'react';
import { useEffect } from 'react';
import InfoCards from './infoCards';
import PayrollPieChart from './payrollPieChart';
import PayrollBarChart from './payrollBarChart';
import DashboardLayout from './dashboardLayout';
import DateRangeSelector from './dateRangeSelector';
import { Grid, Box } from '@mui/material';
import { useStateContext } from '../../contexts/ContextProvider';
import DataFilter from '../global/DataFilter';
import { getTimesheetData } from '../../services/leaveService';
import { width } from '@mui/system';

const DashboardPage = () => {
  //ikmport from statecontext
  const { setIsLoading, user, employees, setEmployees, setTimesheetData, setParmCodes, setTimesheetSettings, 
          setTimesheetApprovers, setJobs, setDayTypes, setAllPayrolls, setAllPayPoints, setSelectedTimekeeper, setSelectedTimesheetData,
          setAllCostCodes, setSelectedPayroll, setSelectedPayPoint, setSelectedCostCode,
          setAvailablePayrolls, setAvailablePayPoints, setAvailableCostCodes, setPayrollUsers, setPayrollRights, setInitialEmployees,
          setAvailableReports } = useStateContext();

  useEffect(() => {
    const fetchLeaveValues = async () => {
      setIsLoading(true);
      
      if(employees.length === 0){
        const data = await getTimesheetData(user);
        let emps = data.employees;
        const timesheet_data = data.timesheet_data;
        let parm_codes = data.parm_codes;
        parm_codes = parm_codes.filter(code => code.currency);
        const timesheet_settings = data.timesheet_settings;
        const day_types = data.day_types;
        const jobs_fetched = data.jobs;
        const timesheet_approvers = data.timesheet_approvers;
        const payroll_users = data.payroll_users;
        const payroll_rights = data.payroll_rights;
        const available_payrolls = data.available_payrolls;
        const available_paypoints = data.available_paypoints;
        const available_costcodes = data.available_costcodes;
        const all_payrolls = data.all_payrolls;
        const all_paypoints = data.all_paypoints;
        const all_costcodes = data.all_costcodes;
        const available_reports = data.available_reports

        setAvailableReports(available_reports);
        setSelectedTimekeeper(payroll_users[0]);
        setSelectedTimesheetData(timesheet_data);
        setInitialEmployees(emps);
        
        setAllPayrolls(all_payrolls);
        setAllPayPoints(all_paypoints);
        setAllCostCodes(all_costcodes);        
        setSelectedPayroll(available_payrolls[0]);
        setSelectedPayPoint(available_paypoints[0]);
        setSelectedCostCode(available_costcodes[0]);
        setAvailablePayrolls(available_payrolls);
        setAvailablePayPoints(available_paypoints);
        setAvailableCostCodes(available_costcodes);
        setPayrollRights(payroll_rights);        
        setPayrollUsers(payroll_users);
        setTimesheetApprovers(timesheet_approvers);
        setEmployees(emps);
        setTimesheetData(timesheet_data);
        setParmCodes(parm_codes);
        setTimesheetSettings(timesheet_settings);
        setDayTypes(day_types);
        setJobs(jobs_fetched);

      } else {
        // console.log(initialModifiedTimesheetData);
      }

      setIsLoading(false);
    };
    
    fetchLeaveValues();

  }, []);

  const handleDateRangeChange = (dateRange) => {
    console.log("Selected Date Range:", dateRange);
    // Update charts and data based on the selected date range
  };

  // setIsLoading(false);

  return (
    <Box>
      <DashboardLayout>
        <DataFilter timekeeper ={true} payroll={true} paypoint={true} costcode={true} employee={true}/>
        <Box sx={{width: '30%', mt: 3}}>
          <DateRangeSelector onDateRangeChange={handleDateRangeChange} />
        </Box>  
        <InfoCards />
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Grid item xs={12} md={6}>
            <PayrollPieChart />
          </Grid>
          <Grid item xs={12} md={6}>
            <PayrollBarChart />
          </Grid>
        </Grid>
      </DashboardLayout>
    </Box>
  );
};

export default DashboardPage;
