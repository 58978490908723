import * as React from 'react';
import { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useStateContext } from "../../contexts/ContextProvider";
import { getMaintenanceMode, toggleMaintenanceMode, getTimesheetData, editTimesheetSettings, editDayTypes, editJobs, editTimesheetApprovers, editPayrollUsers, editPayrollRights, editEmployeeDefaults
      } from "../../services/leaveService";
import { getEmployees } from '../../services/userService';
import { getAvailableBackups, downloadBackup, backupDB, restoreDB, restoreDBFromUpload } from '../../services/filesService';
import CustomDataGrid from '../../components/CustomDataGrid';
import CollapsableCard from '../../components/CollapsableCard';
import Select from 'react-select';
import Save from '@mui/icons-material/Save';
import format from 'date-fns/format';
import CustomSnackBar from '../../components/CustomSnackBar';
import * as XLSX from 'xlsx';



const TimesheetSettingsPage = () => {
  let { user, 
        employees, setEmployees, accrualRuns, setAccrualRuns, isLoading, setIsLoading, dbBackups, setDBBackups, setSnackbar, 
        maintenanceMode, setMaintenanceMode, parmCodes, setParmCodes, timesheetSettings, setTimesheetSettings, timesheetApprovers, setTimesheetApprovers,
        jobs, setJobs, dayTypes, setDayTypes, timesheetData, setTimesheetData, payrollUsers, setPayrollUsers, payrollRights, setPayrollRights,
        allPayrolls, setAllPayrolls, allPayPoints, setAllPayPoints, allCostCodes, setAllCostCodes,
        selectedPayroll, setSelectedPayroll, selectedPayPoint, setSelectedPayPoint, selectedCostCode, setSelectedCostCode,
        availablePayrolls, setAvailablePayrolls, availablePayPoints, setAvailablePayPoints, availableCostCodes, setAvailableCostCodes,
        availableReports, setAvailableReports, employeeDefaults, setEmployeeDefaults
      } = useStateContext(); 

  const [importData, setImportData] = useState(null);

  useEffect(() => {
    const fetchLeaveValues = async () => {
      setIsLoading(true);
      const maintenance_mode = await getMaintenanceMode();
      setMaintenanceMode(maintenance_mode);

      const data = await getTimesheetData(user);
      const emps = data.employees;
      const timesheet_data = data.timesheet_data;
      let parm_codes = data.parm_codes;
      parm_codes = parm_codes.filter(code => code.currency);
      const timesheet_settings = data.timesheet_settings;
      const day_types = data.day_types;
      const jobs = data.jobs;
      const timesheet_approvers = data.timesheet_approvers;
      const payroll_users = data.payroll_users;
      const payroll_rights = data.payroll_rights;
      const available_payrolls = data.available_payrolls;
      const available_paypoints = data.available_paypoints;
      const available_costcodes = data.available_costcodes;
      const all_payrolls = data.all_payrolls;
      const all_paypoints = data.all_paypoints;
      const all_costcodes = data.all_costcodes;
      const available_reports = data.available_reports;
      const employee_defaults = data.employee_defaults;

      setEmployeeDefaults(employee_defaults);
      setAvailableReports(available_reports);
      setAllPayrolls(all_payrolls);
      setAllPayPoints(all_paypoints);
      setAllCostCodes(all_costcodes);
      setSelectedPayroll(available_payrolls[0]);
      setSelectedPayPoint(available_paypoints[0]);
      setSelectedCostCode(available_costcodes[0]);
      setAvailablePayrolls(available_payrolls);
      setAvailablePayPoints(available_paypoints);
      setAvailableCostCodes(available_costcodes);
      setPayrollRights(payroll_rights);
      setPayrollUsers(payroll_users);
      setTimesheetApprovers(timesheet_approvers);
      setEmployees(emps);
      setTimesheetData(timesheet_data);
      setParmCodes(parm_codes);
      setTimesheetSettings(timesheet_settings);
      setDayTypes(day_types);
      setJobs(jobs);

      // const initialModifiedTimesheetData = await buildModifiedTimesheetData(selectedDate, timesheet_data, emps, timesheetSettings, dayTypes, jobs);
      // setModifiedTimesheetData(initialModifiedTimesheetData);

      setIsLoading(false);
      // setIsInitialLoading(false);

    };
    
    // initialEvents = fetchLeaveValues();
    if (employees.length === 0){
      fetchLeaveValues();
    } else {
      setIsLoading(false);
      // setIsInitialLoading(false);
    }
  }, []);

  useEffect(() => {

  }, [maintenanceMode]);

  const handleDownloadBackup = async (filename) => {
    setIsLoading(true);
    await downloadBackup(filename);
    setIsLoading(false);
  }

  const handleRestoreBackup = async (filename) => {
    setIsLoading(true);
    await restoreDB(filename);
    setIsLoading(false);
  }

  const handleSQLFileChange = async (event) => {
    // setUploadedBackup(event.target.files[0]);
    setIsLoading(true);
    const result = await restoreDBFromUpload(event.target.files[0]);
    setIsLoading(false);
  };

  const replaceKeys = (obj, mapping) => {
    return Object.keys(obj).reduce((acc, key) => {
      const newKey = mapping[key] || key;
      acc[newKey] = ["yes", "Yes", 1, "1", true, "True", "true"].includes(obj[key]) ? 1 : ["no", "No", 0, "0", false, "False", "false"].includes(obj[key]) ? 0 : obj[key];
      return acc;
    }, {});
  };

  const convertFloatToTime = (time) => {
    time = parseFloat(time) * 24;
    const integerPart = Math.floor(time);
    const decimalPart = time - integerPart;

    const hour = integerPart.toString().padStart(2, '0');
    const minute = Math.round(decimalPart * 60).toString().padStart(2, '0');

    return `${hour}:${minute}`;
  };

  const handleXLFileUpload = async (e, editMethod, setRows, timesheet_setting) => {
    setIsLoading(true);
    const file = e.target.files[0];
    const reader = new FileReader();
    let newList = [];
    let newKeyList = [];

    reader.onload = (event) => {
      const arrayBuffer = event.target.result;
      const workbook = XLSX.read(new Uint8Array(arrayBuffer), { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);
      setImportData(jsonData);

      // console.log(jsonData);
      // console.log(leaveTypes);
      
      if (timesheet_setting === "employee_defaults"){
        const keyMapping = {
          "EmpNo": "EmpNo",
          "Job Name": "job_id"
        }
  
        // Object.entries(jsonData[0]).forEach(([key, value]) => {
        //   keyMapping[key] = leaveTypes.find(item => item.name === key)?.id || "id";
        // });
  
        console.log(keyMapping);
  
        newKeyList = jsonData.map(item => replaceKeys(item, keyMapping));

        newList = newKeyList.map(item => {
          return {
            ...item,
            "job_id": jobs.find(job => job.name === item.job_id)?.id || 0
          }
        });
  
        console.log("1", newList);
      } else if (timesheet_setting === "jobs"){
        const keyMapping = {
          "Id": "id",
          "Job Name": "name"
        }
  
        console.log(keyMapping);
  
        newList = jsonData.map(item => replaceKeys(item, keyMapping));
      } else if (timesheet_setting === "timesheet_settings"){
        const keyMapping = {
          "Id": "id",
          "Day Type" : "day_type_id",
          "Job": "job_id",
          "Value Code" : "value_ordinal",
          "Amount Code" : "amt_ordinal",
          "Rate": "rate"
        };
  
        console.log(keyMapping);
  
        newKeyList = jsonData.map(item => replaceKeys(item, keyMapping));

        newList = newKeyList.map(item => {
          return {
            ...item,
            "day_type_id": dayTypes.find(day_type => day_type.name === item.day_type_id)?.id || 0,
            "job_id": jobs.find(job => job.name === item.job_id)?.id || 0,
            "value_ordinal": parmCodes.find(code => code.OrdinalNo === (item.value_ordinal).toString().split(" - ")[0])?.OrdinalNo || 0,
            "amt_ordinal": parmCodes.find(code => code.OrdinalNo === (item.amt_ordinal).toString().split(" - ")[0])?.OrdinalNo || 0
          }
        });
      } else {
        newList = jsonData;
      }
    };

    reader.readAsArrayBuffer(file);
    // Wait for the onload event to finish
    await new Promise(resolve => {
      reader.onloadend = resolve;
    });

    const uploadedList = {
      editor_employee_number: user.employee_number,
      rows: newList
    }
    console.log("2", uploadedList);

    const response = await editMethod(uploadedList);
    setRows(response.data.data);
    console.log(response);

    setSnackbar({ children: response.data.message, severity: 'success' });
    setIsLoading(false);
  };

  const handleUploadClick = async (variables) => {
    const fileInput = document.getElementById(`${variables["upload_type"]}-file-input`);
    fileInput.value = null; // Reset the value of the file input

    if (variables["upload_type"] === "xl"){
      fileInput.onchange = (event) => {
        handleXLFileUpload(event, variables["editMethod"], variables["setRows"], variables["leave_setting"]);
      };

    }

    fileInput.click(); // Open the file picker dialog
  };

  const handleToggleMaintenanceMode = async () => {
    setIsLoading(true);

    const result = await toggleMaintenanceMode();
    if (result.status === 200){
      setMaintenanceMode(result.data.maintenance_mode);
      setSnackbar({ children: result.data.message, severity: 'success' });
    } else{
      setSnackbar({ children: "Error occurred.", severity: 'error' });
    }

    setIsLoading(false);
  };

  if (isLoading) {
  // if (isLoading || parmCodes.length === 0) {
    return null;
    // return <CircularProgress />;
  }

  let allOrdinalNos = [];
    parmCodes.forEach(code => {
      allOrdinalNos.push(`${code.OrdinalNo} - ${code.CodeName}`);
  });

  let allJobs = [];
    jobs.forEach(code => {
      allJobs.push(`${code.name}`);
  });

  let allDayTypes = [];
    dayTypes.forEach(code => {
      allDayTypes.push(`${code.name}`);
  });

  let allPayrollUsers = [];
    payrollUsers.forEach(code => {
      allPayrollUsers.push(`${code.id}`);
  });

  // console.log(jobs);
  // console.log(timesheetSettings);
  // console.log(parmCodes);

  // console.log(allJobs);
  // return

  // console.log(allOrdinalNos);

  //TIMESHEET SETTINGS
  const timesheetSettingsColumns = [
    { field: 'id', headerName: 'Id', width: 20 },
    { field: 'day_type_id', headerName: 'Day Type', width: 200, 
      valueGetter: (params) => {
        // console.log(params);
        return dayTypes.find(day_type => day_type.id === parseInt(params.row.day_type_id))?.name;
      },
      valueSetter: (params) => {
        console.log(params);
        let value_id = dayTypes.find(day_type => day_type.name === params.value)?.id;
        value_id = parseInt(value_id);
        return { ...params.row, day_type_id: value_id};
      },
      editable: false
     },
    { field: 'job_id', headerName: 'Job', width: 200, 
      type: 'singleSelect',
      valueOptions: allJobs,
      valueGetter: (params) => {
        // console.log(params);
        return jobs.find(job => job.id === parseInt(params.row.job_id))?.name;
      },
      valueSetter: (params) => {
        console.log(params);
        let value_id = jobs.find(job => job.name === params.value)?.id;
        value_id = parseInt(value_id);
        return { ...params.row, job_id: value_id};
      },
      editable: false
    },
    { field: 'value_ordinal', headerName: 'Value Code', width: 200, 
      type: 'singleSelect',
      valueOptions: allOrdinalNos,
      valueGetter: (params) => {
        if (parmCodes.find(code => parseInt(code.OrdinalNo) === parseInt(params.row.value_ordinal))){
          return `${params.row.value_ordinal} - ${parmCodes.find(code => parseInt(code.OrdinalNo) === parseInt(params.row.value_ordinal))?.CodeName}`
        } else {
          return `${parmCodes[0]?.OrdinalNo} - ${parmCodes[0]?.CodeName}`
        }
      },
      valueSetter: (params) => {
        let [value_ordinal, codeName] = params.value.split(' - ');
        value_ordinal = parseInt(value_ordinal);
        return { ...params.row, value_ordinal };
      },
      editable: true
    },
    { field: 'amt_ordinal', headerName: 'Amount Code', width: 200, 
      type: 'singleSelect',
      valueOptions: allOrdinalNos,
      valueGetter: (params) => {
        if (parmCodes.find(code => parseInt(code.OrdinalNo) === parseInt(params.row.amt_ordinal))){
          return `${params.row.amt_ordinal} - ${parmCodes.find(code => parseInt(code.OrdinalNo) === parseInt(params.row.amt_ordinal))?.CodeName}`
        } else {
          return `${parmCodes[0]?.OrdinalNo} - ${parmCodes[0]?.CodeName}`
        }
      
      },
      valueSetter: (params) => {
        let [amt_ordinal, codeName] = params.value.split(' - ');
        amt_ordinal = parseInt(amt_ordinal);
        return { ...params.row, amt_ordinal };
      },
      editable: true
    },
    { field: 'rate', headerName: 'Rate', width: 100, type: 'float', editable: true},
    // { field: 'value_ordinal', headerName: 'Value Ordinal', width: 300, type: 'date', editable: true,
    //   valueGetter: (params) => new Date(params.row.date),
    //   renderCell: ({ row }) => (
    //     format(new Date(row.date), 'dd-MM-yyyy')
    //   )
    //  },
  ];

  const addTimesheetSettingDict = { day_type_id: "", job_id: "", value_ordinal: "", amt_ordinal: "", rate: "" };

  //APPROVERS SETTINGS
  const timesheetApproversColumns = [
    { field: 'id', headerName: 'Id', width: 20 },
    // { field: 'payroll_id', headerName: 'Payroll Name', width: 300, 
    //   valueGetter: (params) => `${availablePayrolls.find(payroll => payroll.id === params.row.payroll_id)?.payroll_name}`,
    //   valueSetter: (params) => {
    //     return { 
    //       ...params.row, 
    //       payroll_id: availablePayrolls.find(payroll => payroll.payroll_name === params.value)?.id
    //      };
    //   },
    //   editable: true },
    { field: 'payroll', headerName: 'Payroll Name', width: 100, type: 'singleSelect', valueOptions: ["MOSSFIELD",], editable: true },
    { field: 'paypoint', headerName: 'Pay Point', width: 100, type: 'singleSelect', valueOptions: ["Mossfield Farms"], editable: true },
    { field: 'costcode', headerName: 'Cost Code', width: 100, type: 'singleSelect', valueOptions: ['Moreson Farm', 'Chitora Farm', 'Tiny Farm', 'Potaton Farm', 'Muhonde Farm', 'Lonekop Farm', 'Zimati Farm'], editable: true },
    { field: 'approval_order', headerName: 'Approval Order', width: 100, editable: true },
    { field: 'approval_type', headerName: 'Approval Type', width: 100, type: 'singleSelect', valueOptions: ["hierachichal", "any"], editable: true},
  ];

  const addTimesheetApproversDict = { payroll_name: "MOSSFIELD", paypoint: "Mossfield Farms", costcode: 'Moreson Farm', approval_order: "[0]", approval_type: "any" };

  //PAYROLL USERS SETTINGS
  const payrollUsersColumns = [
    { field: 'id', headerName: 'Id', width: 20 },
    { field: 'surname', headerName: 'Surname', width: 100, editable: true },
    { field: 'name', headerName: 'Name', width: 100, editable: true },
    { field: 'initials', headerName: 'Initials', width: 100, editable: true },
    { field: 'email', headerName: 'Email', width: 200, editable: true },
    { field: 'access_level', headerName: 'Access Level', width: 100, editable: true },
  ];

  const addPayrollUsersDict = { surname: "", name: "", initials: "", email: "", access_level: ""};

  //PAYROLL RIGHTS SETTINGS
  const payrollRightsColumns = [
    // { field: 'id', headerName: 'Id', width: 20 },
    { field: 'user_id', headerName: 'User ID', width: 20, type: 'singleSelect', valueOptions: allPayrollUsers, editable: true },
    { field: 'surname', headerName: 'Surname', width: 100, 
      valueGetter: (params) => {
        return `${payrollUsers.find(user => user.id === parseInt(params.row.user_id))?.surname}`;
      },
    },
    { field: 'name', headerName: 'Name', width: 100, 
      valueGetter: (params) => {
        return `${payrollUsers.find(user => user.id === parseInt(params.row.user_id))?.name}`;
      },
    },
    { field: 'payroll', headerName: 'Payroll Name', width: 100, type: 'singleSelect', valueOptions: allPayrolls, editable: true },
    { field: 'paypoint', headerName: 'Pay Point', width: 100, type: 'singleSelect', valueOptions: allPayPoints, editable: true },
    { field: 'costcode', headerName: 'Cost Code', width: 100, type: 'singleSelect', valueOptions: allCostCodes, editable: true },
  ];

  const addPayrollRightsDict = { user_id: "", payroll: "", paypoint: "", costcode: '' };
  // const addPayrollRightsDict = { user_id: "", surname: "", name: "", payroll: "", paypoint: "", costcode: '' };

  //JOBS SETTINGS
  const jobsColumns = [
    { field: 'id', headerName: 'Id', width: 100 },
    { field: 'name', headerName: 'Job Name', width: 200, editable: true },
  ];

  const addJobsDict = { name: "" };

  //DAY TYPES SETTINGS
  const dayTypesColumns = [
    { field: 'id', headerName: 'Id', width: 100 },
    { field: 'name', headerName: 'Day Type Name', width: 200, editable: true },
  ];

  const addDayTypesDict = { name: '' };

  //EMPLOYEE DEFAULTS SETTINGS
  const employeeDefaultsColumns = [
    { field: 'EmpNo', headerName: 'EmpNo', width: 100 },
    { field: 'job_id', headerName: 'Job Name', width: 200, type: 'singleSelect', valueOptions: allJobs,
      valueGetter: (params) => {
        return jobs.find(job => job.id === parseInt(params.value))?.name;
      },
      valueSetter: (params) => {
        let value_id = jobs.find(job => job.name === params.value)?.id;
        value_id = parseInt(value_id);
        return { ...params.row, job_id: value_id};
      },
      editable: true
     },
  ];

  const addEmployeeDefaultsDict = { name: '' };

  // //EMPLOYEES
  // const options = [
  //   { value: 1, label: 'Option 1' },
  //   { value: 2, label: 'Option 2' },
  //   { value: 3, label: 'Option 3' },
  // ];

  // const MultiSelectCell = ({ value, id, field, api }) => {
  //   const [selectedOptions, setSelectedOptions] = useState(value || []);
  
  //   const handleChange = (newOptions) => {
  //     setSelectedOptions(newOptions);
  //     api.setRowData(id, { ...api.getRow(id), [field]: newOptions }); // Update data
  //   };
  
  //   return (
  //     <Select
  //       isMulti
  //       value={selectedOptions}
  //       options={options}
  //       onChange={handleChange}
  //     />
  //   );
  // };

  // const employeesColumns = [
  //   { field: 'employee_number', headerName: 'EmpNo', width: 20 },
  //   { field: 'name', headerName: 'Name', width: 150 },
  //   { field: 'surname', headerName: 'Surname', width: 150 },
  //   { field: 'email', headerName: 'Email', width: 250 },
  //   { field: 'leave_group_id', headerName: 'Leave Group', width: 100, type: 'singleSelect', valueOptions: leave_group_ids, editable: true},
  //   { field: 'access_level', headerName: 'Access Level', width: 100, type: 'singleSelect', valueOptions: ["1", "2", "3"], editable: true},
  //   { field: 'stop_accrual',  headerName: 'Stop Accrual', width: 150, type: 'boolean', editable: true,
  //     valueGetter: (params) => params.row.employee_information.stop_accrual,
  //     valueSetter: (params) => {
  //       const updatedData = {...params.row};
  //       updatedData.employee_information.stop_accrual = params.value;
  //       return updatedData; 
  //     }
  //   },
  // ];  

  //BACKUPS
  const backupsColumns = [
    { field: 'id', headerName: 'Id', width: 20 },
    { field: 'filename', headerName: 'File Name', width: 300, editable: false },
    { field: 'download', headerName: 'Download', width: 100, 
      renderCell: ({ row }) => (
        <Button 
          onClick={() => handleDownloadBackup(row.filename)} 
          type="button" 
          color="secondary" 
          variant="contained"
        >
          Download
        </Button>
      )
    },
    { field: 'restore', headerName: 'Restore', width: 100, 
      renderCell: ({ row }) => (
        <Button 
          onClick={() => handleRestoreBackup(row.filename)} 
          type="button" 
          color="secondary" 
          variant="contained"
        >
          Restore
        </Button>
      )
    },
  ];

  return (
    <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <CustomSnackBar />
      <Button 
          onClick={() => handleToggleMaintenanceMode()} 
          type="button" 
          color={maintenanceMode ? "error": "secondary"} 
          // color={maintenanceMode ? "secondary": "error"} 
          variant="contained"
        >
          {`Turn ${maintenanceMode ? "OFF": "ON"} maintenance mode`}
      </Button>

      <Box sx={{ mt: 2 }} /> {/* Add spacing between the cards */}

      <CollapsableCard 
        cardHeader="TIMESHEET SETTINGS"
        expanded={false}
        cardContent={
          <>
            <CustomDataGrid
                rows={timesheetSettings}
                setRows={setTimesheetSettings}
                otherColumns={[timesheetSettingsColumns, addTimesheetSettingDict]}
                backendCRUD={{
                  "C": editTimesheetSettings,
                  "R": getAvailableBackups,
                  "U": editTimesheetSettings,
                  "D": null,
                  "TYPE": "TIMESHEET SETTINGS",
                }}
                actions={true}
                extraButtons={[
                  {icon: <AddIcon />, label: "Import", func: handleUploadClick, func_variables: {upload_type:"xl", leave_setting: "timesheet_settings"}, func_type: "import excel"},
                ]} 
              />
          </>
        }
      />

      <Box sx={{ mt: 2 }} /> {/* Add spacing between the cards */}

      <CollapsableCard 
        cardHeader="DAY TYPES"
        expanded={false}
        cardContent={
          <>
            <CustomDataGrid
                rows={dayTypes}
                setRows={setDayTypes}
                otherColumns={[dayTypesColumns, addDayTypesDict]}
                backendCRUD={{
                  "C": editDayTypes,
                  "R": getAvailableBackups,
                  "U": editDayTypes,
                  "D": null,
                  "TYPE": "DAY TYPES",
                }}
                actions={true}
                extraButtons={[
                  {icon: <AddIcon />, label: "Import", func: handleUploadClick, func_variables: {upload_type:"xl", leave_setting: "day_types"}, func_type: "import excel"},
                ]} 
              />
          </>
        }
      />

      <Box sx={{ mt: 2 }} /> {/* Add spacing between the cards */}

      <CollapsableCard 
        cardHeader="JOBS"
        expanded={false}
        cardContent={
          <>
            <CustomDataGrid
                rows={jobs}
                setRows={setJobs}
                otherColumns={[jobsColumns, addJobsDict]}
                backendCRUD={{
                  "C": editJobs,
                  "R": getAvailableBackups,
                  "U": editJobs,
                  "D": null,
                  "TYPE": "JOBS",
                }}
                actions={true}
                extraButtons={[
                  {icon: <AddIcon />, label: "Import", func: handleUploadClick, func_variables: {upload_type:"xl", leave_setting: "jobs"}, func_type: "import excel"},
                ]} 
              />
          </>
        }
      />

      <Box sx={{ mt: 2 }} /> {/* Add spacing between the cards */}

      <CollapsableCard 
        cardHeader="EMPLOYEE DEFAULTS"
        expanded={false}
        cardContent={
          <>
            <CustomDataGrid
                rows={employeeDefaults}
                setRows={setEmployeeDefaults}
                otherColumns={[employeeDefaultsColumns, addEmployeeDefaultsDict]}
                backendCRUD={{
                  "C": null,
                  "R": getAvailableBackups,
                  "U": editEmployeeDefaults,
                  "D": null,
                  "TYPE": "EMPLOYEE DEFAULTS",
                }}
                actions={true}
                extraButtons={[
                  {icon: <AddIcon />, label: "Import", func: handleUploadClick, func_variables: {upload_type:"xl", leave_setting: "employee_defaults"}, func_type: "import excel"},
                ]} 
              />
          </>
        }
      />

      <Box sx={{ mt: 2 }} /> {/* Add spacing between the cards */}

      <CollapsableCard 
        cardHeader="TIMESHEET APPROVERS"
        expanded={false}
        cardContent={
          <>
            <CustomDataGrid
                rows={timesheetApprovers}
                setRows={setTimesheetApprovers}
                otherColumns={[timesheetApproversColumns, addTimesheetApproversDict]}
                backendCRUD={{
                  "C": editTimesheetApprovers,
                  "R": getAvailableBackups,
                  "U": editTimesheetApprovers,
                  "D": null,
                  "TYPE": "TIMESHEET APPROVERS",
                }}
                actions={true}
                extraButtons={[
                ]} 
              />
          </>
        }
      />

      <Box sx={{ mt: 2 }} /> {/* Add spacing between the cards */}

      <CollapsableCard 
        cardHeader="PAYROLL USERS"
        expanded={false}
        cardContent={
          <>
            <CustomDataGrid
                rows={payrollUsers}
                setRows={setPayrollUsers}
                otherColumns={[payrollUsersColumns, addPayrollUsersDict]}
                backendCRUD={{
                  "C": editPayrollUsers,
                  "R": getAvailableBackups,
                  "U": editPayrollUsers,
                  "D": null,
                  "TYPE": "PAYROLL USERS",
                }}
                actions={true}
                extraButtons={[
                ]} 
              />
          </>
        }
      />

      <Box sx={{ mt: 2 }} /> {/* Add spacing between the cards */}

      <CollapsableCard 
        cardHeader="PAYROLL RIGHTS"
        expanded={false}
        cardContent={
          <>
            <CustomDataGrid
                rows={payrollRights}
                setRows={setPayrollRights}
                otherColumns={[payrollRightsColumns, addPayrollRightsDict]}
                backendCRUD={{
                  "C": editPayrollRights,
                  "R": getAvailableBackups,
                  "U": editPayrollRights,
                  "D": null,
                  "TYPE": "PAYROLL RIGHTS",
                }}
                actions={true}
                extraButtons={[
                ]} 
              />
          </>
        }
      />

      <Box sx={{ mt: 2 }} /> {/* Add spacing between the cards */}

      <input id="sql-file-input" type="file" accept=".sql" style={{ display: 'none' }} onChange={handleSQLFileChange} />
      <input id="xl-file-input" type="file" accept=".xlsx" style={{ display: 'none' }} />
      {/* onChange={handleXLFileUpload} /> */}

      <CollapsableCard 
        cardHeader="BACKUPS"
        expanded={false}
        cardContent={
          <>
            <CustomDataGrid
                rows={dbBackups}
                setRows={setDBBackups}
                otherColumns={[backupsColumns, []]}
                backendCRUD={{
                  "C": null,
                  "R": getAvailableBackups,
                  "U": null,
                  "D": null,
                  "TYPE": "BACKUP",
                }}
                actions={false}
                extraButtons={[
                  { icon: <Save />, label: "Backup", func: backupDB, func_variables: {}, func_type: "backup" },
                  { icon: <AddIcon />, label: "Restore", func:() => handleUploadClick({"upload_type": "sql"}), func_variables: {}, func_type: "restore" },
                ]} 
              />
          </>
        }
      />

      <Box sx={{ mt: 2 }} /> {/* Add spacing between the cards */}

    </Box>
  );  
};



export default TimesheetSettingsPage;