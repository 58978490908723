import React from 'react';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';

const data = [
  { name: 'Salaries', value: 8000 },
  { name: 'Bonuses', value: 3000 },
  { name: 'Overtime', value: 4000 },
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28'];

const PayrollPieChart = () => (
  <ResponsiveContainer width="100%" height={300}>
    <PieChart>
      <Pie data={data} dataKey="value" cx="50%" cy="50%" outerRadius={100}>
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip />
    </PieChart>
  </ResponsiveContainer>
);

export default PayrollPieChart;
