import { useEffect, useState } from "react";
import { Box, Button, TextField, MenuItem, Tooltip, Typography, Radio, RadioGroup, FormLabel, FormControlLabel, Autocomplete } from "@mui/material";

import { Formik, useFormikContext } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { useStateContext } from "../../contexts/ContextProvider";

import { exportReports } from "../../services/leaveService";
import DateRangeSelector from "../dashboard/dateRangeSelector";

import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import DateRangeComp from "../../components/datepickers/DateRangeComp";
import format from 'date-fns/format'
import { addDays, set } from 'date-fns'

import CircleNumber from "../../components/CircleNumber";
import Snackbar from '@mui/material/Snackbar';

import LoadingOverlay from "../global/LoadingOverlay";






const ReportExporterForm = ({  }) => {
  const { user, employees, setOpenLeaveApplicationPopup, setOpenFailedPopup, setSnackbar, setResultMessage, setApproveeLeaveTaken, range,
          setLeaveTaken, leaveTypes, isLoading, setIsLoading, setViewPDFViewer, setPdfUrl, selectedPayroll, selectedReport, setSelectedReport } = useStateContext();

  // const [isHovered, setIsHovered] = useState(false);
  // const [selectedStatus, setSelectedStatus] = useState(viewedLeave[0].status);

  const isNonMobile = useMediaQuery("(min-width:600px)");
  // console.log(employees);
  
  // const viewedEmployee = (employees.filter((row) => row.employee_number === viewedLeave[0].employee_number))[0]

  const initialValues = {
    export_to: "PDF",
    employee_range : "no",
    employee_start_range : "",
    employee_end_range : "",
    filter_by: "all",
    print_from: "runfile",
    group_by: "ALL"
  };
  
  // const handleFormSubmit = async (form_values) => {
  //   setIsLoading(true);

  //   const data = {
  //                 rows: [{...viewedLeave[0], action_reason: form_values.action_reason, status: form_values.status}],
  //                 editor_employee_number:user.employee_number,
  //               };

  //   const action_leave_response = await actionLeaveTaken(data);

  //   setOpenLeaveApplicationPopup(false);

  //   if (![200, 202].includes(action_leave_response["status"])){
  //     setSnackbar({ children: "Error while updating.", severity: 'error' });
  //     setIsLoading(false);
  //     setResultMessage(action_leave_response["data"]["message"]);
  //     setOpenFailedPopup(true);
  //     return;
  //   }

  //   setSnackbar(action_leave_response["status"] === 200 ? { children: 'Leave successfully actioned', severity: 'success' } : { children: 'Leave details changed', severity: 'error' });
  //   setApproveeLeaveTaken(action_leave_response.data.approvee_leave_taken);
  //   setLeaveTaken(action_leave_response.data.employee_leave_taken);
  //   setIsLoading(false);
  // };

  const handlePreviewOrDownloadClick = async (values, action) => {
    setIsLoading(true);
    const date_from = format(range[0].startDate, 'd/M/yyyy');
    const date_to = format(range[0].endDate, 'd/M/yyyy');

    const report_values = {
      ...values, 
      payroll: selectedPayroll,
      date_from: date_from, 
      date_to: date_to,
      report_name: selectedReport,
    };
    // console.log("REPORT VALUES", report_values);
    // return;

    const response = await exportReports(report_values);
    // return;
    
    if (action === "download"){
      const link = document.createElement('a');
      link.href = response.data.report_link;
      link.target = '_blank';  // This will open the link in a new tab
      link.download = `${response.data.report_name}.pdf`;
      console.log("REPORT NAME", `${response.data.report_name}.pdf`);
      document.body.appendChild(link);
      // link.click();
      window.open(link.href, '_blank');

      document.body.removeChild(link);

    } else {
      setOpenLeaveApplicationPopup(false);
      setPdfUrl(response.data.report_link);
      setViewPDFViewer(true);
    }
    setIsLoading(false);
    
  };

  // console.log("RANGE", range);
  
  // const enabledOrDisabledButton = (viewedLeave[0].attachment === null) ? true : false;

  return (
    <Box m="20px">
      {/* <PDFViewer pdfUrl={pdfUrl} /> */}

      <Header title="REPORT EXPORTER" subtitle={selectedReport} />

      <Formik
        onSubmit={(values) => console.log("ONSUBMIT", values)}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={()=> console.log("handleSubmit")}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              {/* DATE RANGE SELECTOR */}
              <Box sx={{ gridColumn: "span 1" }} >
                <Typography sx={{textAlign: "right"}}>Select Date Range</Typography>
              </Box>
              
              <Box 
                sx={{ gridColumn: "span 3" }}
              >
                <LocalizationProvider 
                  dateAdapter={AdapterDayjs}
                >
                  <DateRangeComp />
                </LocalizationProvider>
              </Box>

              {/* EXPORT TO RADIO */}
              <Box sx={{ border: '1px solid black', borderRadius: '4px', padding: '16px', display: 'inline-block', gridColumn: "span 1", "& .MuiInputBase-root.Mui-disabled": {fontWeight: "bold", opacity: 1, color: "rgba(0, 0, 0, 0.6)"}}}>
                <FormLabel id="export-to-radio" sx={{ fontWeight: "bold", opacity: 1, color: "rgba(0, 0, 0, 0.6)" }}>Export To</FormLabel>
                <RadioGroup
                  aria-labelledby="export-to-radio"
                  defaultValue="PDF"
                  name="export-to-radio"
                  // sx={{ gridColumn: "span 1", "& .MuiInputBase-root.Mui-disabled": {fontWeight: "bold", opacity: 1, color: "rgba(0, 0, 0, 0.6)"} }}
                  onChange={(event) => setFieldValue('export_to', event.target.value)}
                >
                  <FormControlLabel value="PDF" control={<Radio />} label="PDF" />
                  <FormControlLabel value="EXCEL" control={<Radio />} label="EXCEL" disabled={true}/>
                </RadioGroup>
              </Box>
              
              {/* LOGO BOX */}
              <Box sx={{ border: '1px solid black', borderRadius: '4px', padding: '16px', display: 'inline-block', gridColumn: "span 3", "& input": {fontWeight: "bold", opacity: 1} }} style={{ visibility: 'hidden' }}>
                <TextField
                  id="logo"
                  disabled
                  fullWidth
                  defaultValue={""}
                  type="text"
                  label="Logo"
                  name="logo"
                  // sx={{ gridColumn: "span 3", "& input": {fontWeight: "bold", opacity: 1} }}
                  // style={{ visibility: 'hidden' }}
                />
              </Box>

              {/* EMPLOYEE RANGE RADIO */}
              <Box sx={{ border: '1px solid black', borderRadius: '4px', padding: '16px', display: 'inline-block', gridColumn: "span 1", "& .MuiInputBase-root.Mui-disabled": {fontWeight: "bold", opacity: 1, color: "rgba(0, 0, 0, 0.6)"}}}>
                <FormLabel id="employee-range-radio" sx={{ fontWeight: "bold", opacity: 1, color: "rgba(0, 0, 0, 0.6)" }}>Employee Range</FormLabel>
                <RadioGroup
                  aria-labelledby="employee-range-radio"
                  defaultValue="no"
                  name="employee-range-radio"
                  value={values.employee_range}
                  onChange={(event) => setFieldValue('employee_range', event.target.value)}
                >
                  <FormControlLabel value="no" control={<Radio />} label="ALL" />
                  <FormControlLabel value="yes" control={<Radio />} label="RANGE" />
                </RadioGroup>
              </Box>

              {/* EMPLOYEE RANGE BOX */}
              <Box sx={{ borderRadius: '4px', padding: '16px', display: 'inline-block', border: '1px solid black', gridColumn: "span 3" }}>
                <Autocomplete
                  options={employees} disabled={values.employee_range === "no"}
                  getOptionLabel={(employee) => `${employee.EmpNo} - ${(employee.GivenNames).toUpperCase()} ${(employee.Surname).toUpperCase()}` }
                  renderInput={(params) => (
                      <TextField {...params} label="Range From" variant="outlined" />
                  )}
                  onChange={(event, newValue) => {
                      if (newValue !== null) { 
                        setFieldValue('employee_start_range', newValue.EmpNo);
                      }

                  }}
                  isOptionEqualToValue={(employee, value) => employee.EmpNo === value.EmpNo}
                />
                <Box mt={1} />
                <Autocomplete
                  options={employees} disabled={values.employee_range === "no"}
                  getOptionLabel={(employee) => `${employee.EmpNo} - ${(employee.GivenNames).toUpperCase()} ${(employee.Surname).toUpperCase()}` }
                  renderInput={(params) => (
                      <TextField {...params} label="Range To" variant="outlined" />
                  )}
                  onChange={(event, newValue) => {
                      if (newValue !== null) { 
                        setFieldValue('employee_end_range', newValue.EmpNo);
                      }

                  }}
                  isOptionEqualToValue={(employee, value) => employee.EmpNo === value.EmpNo}
                />
              </Box>

              {/* GROUP BY DROPDOWN */}
              <Box sx={{ border: '1px solid black', borderRadius: '4px', padding: '16px', display: 'inline-block', gridColumn: "span 4", "& input": {fontWeight: "bold", opacity: 1} }}>
                <Autocomplete
                  options={["ALL", "EmpNo", "CostCodes"]}
                  getOptionLabel={(group_var) => group_var}
                  renderInput={(params) => (
                      <TextField {...params} label="Group By" variant="outlined" />
                  )}
                  defaultValue={selectedReport === "Employee Statement" ? "EmpNo" : "ALL"}
                  onChange={(event, newValue) => {
                      if (newValue !== null) { 
                        setFieldValue('group_by', newValue);
                      }

                  }}
                  isOptionEqualToValue={(group_var, value) => group_var === value}
                  // isOptionEqualToValue={(group_var, value) => employee.EmpNo === value.EmpNo}
                />
              </Box>

              {/* FILTER BY RADIO */}
              <Box sx={{ border: '1px solid black', borderRadius: '4px', padding: '16px', display: 'inline-block', gridColumn: "span 4", "& input": {fontWeight: "bold", opacity: 1} }}>
                <FormLabel id="filter-by-radio" sx={{ fontWeight: "bold", opacity: 1, color: "rgba(0, 0, 0, 0.6)", textAlign: "center", display: 'block' }}>Filter By</FormLabel>
                <RadioGroup
                  row
                  id="filter-by-radio"
                  aria-labelledby="filter-by-radio"
                  defaultValue="all"
                  name="filter-by-radio"
                  sx={{ display:"flex", justifyContent:"space-between", width:"100%", gap:"10px", mt:"20px" }}
                  onChange={(event) => setFieldValue('filter_by', event.target.value)}
                >
                  <FormControlLabel value="all" control={<Radio size="small"/>} label="  ALL" />
                  <FormControlLabel value="active" control={<Radio size="small"/>} label="  Active" />
                  <FormControlLabel value="terminated" control={<Radio size="small"/>} label="  Terminated" />
                </RadioGroup>
              </Box>

              {/* PRINT FROM RADIO */}
              {/* <Box sx={{ border: '1px solid black', borderRadius: '4px', padding: '16px', display: 'inline-block', gridColumn: "span 4", "& input": {fontWeight: "bold", opacity: 1} }}>
                <FormLabel id="print-from-radio" sx={{ fontWeight: "bold", opacity: 1, color: "rgba(0, 0, 0, 0.6)", textAlign: "center", display: 'block' }}>Print From</FormLabel>
                <RadioGroup
                  row
                  id="print-from-radio"
                  aria-labelledby="print-from-radio"
                  defaultValue="runfile"
                  name="print-from-radio"
                  sx={{ display:"flex", justifyContent:"space-between", width:"100%", gap:"10px", mt:"20px" }}
                  onChange={(event) => setFieldValue('print_from', event.target.value)}
                >
                  <FormControlLabel value="runfile" control={<Radio />} label="  Runfile" />
                  <FormControlLabel value="validation" control={<Radio />} label="  Validation" />
                  <FormControlLabel value="consolidation" control={<Radio />} label="  Consolidation" />
                  <FormControlLabel value="history" control={<Radio />} label="  History" />
                </RadioGroup>
              </Box> */}


            </Box>

            {/* <Box sx={{ mt: 4 }} /> */}

            <Box sx={{ mt: 4 }} />

            <Box display="flex" justifyContent="space-between" width="100%" gap="10px" mt="20px">
                <Button onClick={() => setOpenLeaveApplicationPopup(false)} type="button" color="secondary" variant="contained">
                    Cancel
                </Button>
                <Button onClick={() => handlePreviewOrDownloadClick(values, "download")} type="button" color="secondary" variant="contained">
                    Download
                </Button>
                <Button onClick={() => handlePreviewOrDownloadClick(values, "preview")} disabled={true} type="button" color="secondary" variant="contained">
                    Preview
                </Button>
            </Box>
          </form>
        )}
      </Formik>

      <LoadingOverlay isLoading={isLoading} />
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  // leave_type: yup.string().required("required"),
  // reason: yup.string().required("required"),
  // attatchment: yup.string().required("required"),
  // email: yup.string().email("invalid email").required("required"),
  // contact: yup
  //   .string()
  //   .matches(phoneRegExp, "Phone number is not valid")
  //   .required("required"),
  // address1: yup.string().required("required"),
  // address2: yup.string().required("required"),
});

export default ReportExporterForm;
