import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';
import DateRangeComp from '../../components/datepickers/DateRangeComp';

const DateRangeSelector = ({ onDateRangeChange }) => {
  const [value, setValue] = useState([null, null]);

  const handleChange = (newValue) => {
    setValue(newValue);
    onDateRangeChange(newValue);
  };

  return (
    <Box sx={{ mb: 3 }}>
        <Typography variant="h6" gutterBottom>
            Select Date Range
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangeComp />
        </LocalizationProvider>
        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateRangePicker
            startText="Start Date"
            endText="End Date"
            value={value}
            onChange={handleChange}
            renderInput={(startProps, endProps) => (
            <>
                <TextField {...startProps} />
                <Box sx={{ mx: 2 }}> to </Box>
                <TextField {...endProps} />
            </>
            )}
        />
        </LocalizationProvider> */}
    </Box>
  );
};

export default DateRangeSelector;
